<template>
  <v-container class="name-number" fluid align="center">
    <v-row justify="center">
      <v-col cols="3">
        <label for="name:">Vorname*</label>
        <v-text-field v-model="firstName" outlined :rules="nameRules" />
      </v-col>
      <v-col cols="3">
        <label for="name:">Name*</label>
        <v-text-field v-model="lastName" outlined :rules="lastNameRules" />
      </v-col>
      <v-col cols="3">
        <label for="initials:">Initialen*</label>
        <v-text-field v-model="initials" outlined :rules="initialsRules" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <label for="phoneNumber:">Rufnummer*</label>
        <v-text-field v-model="phoneNumber" outlined :rules="phoneRules" />
      </v-col>
      <v-col cols="3">
        <label for="email:">Email*</label>
        <v-text-field v-model="email" type="email" outlined :rules="emailRules" />
      </v-col>
      <v-col cols="3">
        <label for="confirmEmail:">Email bestätigen*</label>
        <v-text-field v-model="confirmEmail" type="email" outlined :rules="confirmEmailRules" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <label for="userName:">Benutzer name*</label>
        <v-text-field v-model="userName" outlined :rules="userNameRules" />
      </v-col>
      <v-col cols="3">
        <label for="password:">Passwort*</label>
        <v-text-field v-model="password" type="Password" :rules="passwordRules" outlined />
      </v-col>
      <v-col cols="3">
        <label for="confirmPassword:">Passwort bestätigen*</label>
        <v-text-field v-model="confirmPassword" type="password" outlined :rules="confirmPasswordRules" />
      </v-col>
    </v-row>
    <div class="roles" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
      Rollen:
      <!-- <span>i</span> -->
      <span class="tooltip-icon tooltip" v-if="showTooltip">{{
        tooltipText
      }}</span>
      <!-- <span class="tooltip-icon">i</span>
      <div class="tooltip" v-if="showTooltip">{{ tooltipText }}</div> -->
    </div>

    <v-row justify="center" class="my-n8">
      <v-col cols="2">
        <v-checkbox v-model="selectedRole" value="option1" label="Admin" @change="handleRoleChange" />
      </v-col>
      <v-col cols="4">
        <v-checkbox v-model="selectedRole" value="option2" label=" Verwaltende*r" @change="handleRoleChange" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="2">
        <v-checkbox v-model="selectedRole" value="option3" label="Ausführende*r" @change="handleRoleChange" />
      </v-col>
      <v-col cols="4">
        <v-checkbox v-model="selectedRole" value="option4" label="Inventarbesitzer*in" @change="handleRoleChange" />
      </v-col>
    </v-row>
    <v-row> </v-row>
    <v-row justify="center">
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <label for="description">Beschreibung*</label>
        <v-text-field v-model="description" outlined :rows="2" :height="100" :rules="descriptionRules" />
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn color="primary" @click="createUser" text-color:black>Erstellen</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="gray" @click="resetForm">Abbrechen</v-btn>
      </v-col>
    </v-row>
    <v-alert type="success" elevation="20" v-show="showAlert" :color="alertColor" class="alert">
      {{ alertText }}
    </v-alert>
  </v-container>
</template>
<script>
import axios from "axios";
import UpdateUser from "../pages/UpdateUser.vue";
const UserType = {
  AdminUserType: 1,
  ManagerUserType: 2,
  FieldWorkerUserType: 3,
  InventoryOwner: 4,
};

export default {
  data() {
    return {
      tooltipText:
        "Wähle die Rollen aus: Admin, Ausführende*r, Verwaltende*r, Inventarbesitzer*in",
      showTooltip: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      userType: "",
      userName: "",
      firstName: "",
      lastName: "",
      initials: "",
      phoneNumber: "",
      email: "",
      password: "",
      selectedRole: null,
      description: "",
      option1: false,
      option2: false,
      option3: false,
      option4: false,
      confirmEmail: "",
      confirmPassword: "",
      nameRules: [
        (v) => !!v || "Vorname ist erforderlich",
        (v) =>
        /^[a-zA-ZäöüÄÖÜß ]+$/.test(v) ||
          "Der Vorname darf nur Buchstaben und Leerzeichen enthalten",
      ],
      lastNameRules: [
        (v) => !!v || "Name ist erforderlich",
        (v) =>
        /^[a-zA-ZäöüÄÖÜß ]+$/.test(v) ||
          "Der Name darf nur Buchstaben und Leerzeichen enthalten",
      ],
      initialsRules: [
        (v) => !!v || "Initialen sind erforderlich",
        (v) =>
        /^[a-zA-ZäöüÄÖÜß ]+$/.test(v)||
          "Die Initialen dürfen nur Buchstaben und Leerzeichen enthalten",
        (v) =>
          (v && v.length >= 1 && v.length <= 3) ||
          "Die Initialen müssen zwischen 1 und 3 Zeichen lang sein",
      ],
      phoneRules: [
        (v) => !!v || "Telefonnummer ist erforderlich",
        (v) =>
          /^(?:(\+41|\+49)|0)([\s\d]*)$/.test(v) || "Ungültige Rufnummer. Die Nummer sollte mit +41, +49 oder 0 beginnen.",
      ],
      emailRules: [
        (v) => !!v || "E-mail ist erforderlich",
        (v) => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) || "Ungültige E-mail",
      ],
      confirmEmailRules: [
        (v) => !!v || "E-Mail-Bestätigung ist erforderlich",
        (v) => v === this.email || "E-Mails stimmen nicht überein",
      ],
      userNameRules: [
        (v) => !!v || "Benutzername ist erforderlich",
        (v) =>
          /^[a-zA-Z0-9äöüÄÖÜ\s]+$/.test(v) ||
          "Der Benutzername darf nur Buchstaben und Zahlen enthalten",
      ],
      passwordRules: [(v) => !!v || "Password ist erforderlich "],
      confirmPasswordRules: [
        (v) => !!v || "Passwortbestätigung ist erforderlich",
        (v) => v === this.password || "Passwörter stimmen nicht überein",
      ],
      descriptionRules: [
        (v) => !!v || "Beschreibung ist erforderlich",
        (v) =>
          v.length <= 255 ||
          "Die Beschreibung muss weniger als 255 Zeichen enthalten",
      ],
    };
  },
  components: {
    UpdateUser,
  },
  methods: {
    resetForm() {
      this.$router.push("/app/pages/management/Liste der Nutzenden");
    },
    handleRoleChange(selectedRole) {
      this.selectedRole = selectedRole;

      if (selectedRole !== "option1") this.option1 = false;
      if (selectedRole !== "option2") this.option2 = false;
      if (selectedRole !== "option3") this.option3 = false;
      if (selectedRole !== "option4") this.option4 = false;
    },
    async createUser() {
      let userTypeId = null;
      if (this.selectedRole === "option1") {
        userTypeId = 1;
      } else if (this.selectedRole === "option2") {
        userTypeId = 2;
      } else if (this.selectedRole === "option3") {
        userTypeId = 3;
      } else if (this.selectedRole === "option4") {
        userTypeId = 4;
      }

      const payload = {
        userTypeId: userTypeId,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        username: this.userName,
        password: this.password,
        confirmPassword: this.confirmPassword,
        isActive: true,
        isConfirmed: true,
        description: this.description,
        initials: this.initials
      };

      try {
        const response = await axios.post(
          "https://tilia-ch.rrota.org/api/User/CreateUserViaAdmin",
          payload
        );
        const user = response.data;
        this.$store.commit("ADD_USER", user);
        this.$store.dispatch("fetchUsers");
        this.resetForm();
        if (response.status === 200) {
          this.showAlert = true;
          this.alertColor = "success";
          this.alertText = "Das neue Benutzer wurde erfolgreich erstellt";
          setTimeout(() => {
            this.$router.push({ name: "Liste der Nutzenden" }); // navigate to userList page
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        this.showAlert = true;
        this.alertColor = "error";

        if (error.response) {
          if (error.response.status === 400) {
            const errors = error.response.data.errors;

            if (errors) {
              const descriptionError = errors.Description;

              if (descriptionError) {
                this.alertText = `Fehler beim Anlegen des Benutzers: ${descriptionError[0]}`;
              } else {
                this.alertText =
                  "Fehler beim Anlegen des Benutzers: Einige Felder sind ungültig.";
              }
            } else {
              this.alertText =
                "Fehler beim Anlegen des Benutzers: Benutzername wird bereits verwendet";
            }
          } else {
            this.alertText =
              "Fehler beim Erstellen der Benutzer: Serverfehler.";
          }
        } else if (error.request) {
          this.alertText =
            "Fehler beim Erstellen der Benutzer: Keine Antwort vom Server.";
        } else {
          this.alertText =
            "Fehler beim Erstellen der Benutzer: Unbekannter Fehler.";
        }
        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
      }
    },
  },
};
</script>
<style scoped>
.roles {
  margin: 0 auto;
  margin-bottom: 2%;
  width: 50%;
  padding: 9.2px;
  font-size: 16px;
  float: center;
  /* cursor: help; */
  position: relative;
}

.tooltip-icon {
  font-size: 18px;
  margin-left: 18px;
  cursor: help;
}

.tooltip {
  margin: 0 auto;

  /* position: absolute; */
  text-align: center;

  top: 10%;
  left: 15%;
  display: none;
  font-size: 16px;
  background-color: rgba(146, 140, 140, 0.678);
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 1;
}

.roles:hover .tooltip {
  display: block;
}

.name-number {
  margin-bottom: 2%;
}

.alert {
  position: fixed;
  width: 100%;
  max-width: 784px;
  left: 56%;
  top: 45%;
  transform: translate(-50%, -50%);
  padding: 40px 100px;
}
</style>
