<template>
  <div>
    <v-row :class="viewOnly ? 'view-only' : ''">
      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <v-checkbox
              v-model="treeExaminationCrown.noVisibleDamage"
              hide-details
              label="Keine sichtbaren Schäden*"
              class="custom-checkbox"
              :rules="[checkAtLeastOneSelected]"
              :class="atLeastOne ? '' : 'error--text'"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
      <!-- <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Standort/Sozialgefüge:</p>

            <v-select
              v-model="treeExaminationCrown.locationId"
              :items="locationsItems"
              item-text="name"
              item-value="id"
              hide-details
              outlined
              clearable
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
            </v-select>
          </div>
        </div>
      </v-col> -->

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Grosse Wunden:</p>

            <v-text-field
              v-model="treeExaminationCrown.majorWounds"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Höhlungen:</p>

            <v-text-field
              v-model="treeExaminationCrown.hollows"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Kritische Gabelungen:</p>

            <v-text-field
              v-model="treeExaminationCrown.criticalForks"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Kronenverankerung dynamisch:</p>

            <v-text-field
              v-model="treeExaminationCrown.crownAnchorage"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Kronenverankerung starr:</p>

            <v-text-field
              v-model="treeExaminationCrown.rigid"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Astbrüche:</p>

            <v-text-field
              v-model="treeExaminationCrown.branchFractures"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="Stk."
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Totäste zentral:</p>

            <v-text-field
              v-model="treeExaminationCrown.deadBranches"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Totholz peripher:</p>

            <v-text-field
              v-model="treeExaminationCrown.peripheral"
              type="text"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Sekundärtriebanteil:</p>

            <v-text-field
              v-model="treeExaminationCrown.secondaryDriveShare"
              type="text"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Triebwachstumslänge:</p>

            <v-text-field
              v-model="treeExaminationCrown.shootGrowthLength"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="cm"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Vorzeitiger Laubfall:</p>

            <v-text-field
              v-model="treeExaminationCrown.prematureLeafFall"
              type="text"
              hide-details
              outlined
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Verlichtung:</p>

            <v-text-field
              v-model="treeExaminationCrown.defoliation"
              type="text"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Verfärbung:</p>
            <v-checkbox
              v-model="treeExaminationCrown.discoloration"
              hide-details
              label="Gesamt"
              class="custom-checkbox"
            ></v-checkbox>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Astweise:</p>

            <v-text-field
              v-model="treeExaminationCrown.branchByBranch"
              type="text"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Wipfel dürr zu Kronenlänge:</p>

            <v-text-field
              v-model="treeExaminationCrown.crownLength"
              type="text"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              :rules="rules.numericRule"
              class="number-input"
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- nested expansion panel below -->
        <v-expansion-panels>
          <v-expansion-panel class="nested-accordion">
            <v-expansion-panel-header>
              Beschreibung der Schadstellen
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.necrosis"
                        hide-details
                        label="Nekrose"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.wound"
                        hide-details
                        label="Wunde"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.crack"
                        hide-details
                        label="Riss"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.fungalFruitingBody"
                        hide-details
                        label="Pilzfruchtkörper"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.drillHole"
                        hide-details
                        label="Frassgang/Bohrloch"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- necrose -->
              <v-row
                class="root-damage"
                v-show="treeExaminationCrown.necrosis"
                v-for="(item, index) in necroseDamage"
                :key="'necrosis' + index"
                :class="viewOnly ? 'view-only' : ''"
              >
                <v-col cols="12" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-title">
                        {{ index + 1 }}. Nekrose:
                        <a
                          href="#"
                          v-if="necroseDamage.length > 1"
                          class="remove-root-dmg"
                          @click.prevent="deleteNecroseDmgContainer(index)"
                          ><span class="mdi mdi-close"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Richtung:</p>

                      <v-select
                        v-model="item.inputs.selectedDirection"
                        :items="directionItems"
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        small-chips
                        clearable
                      >
                        <v-icon slot="append" color="white">
                          mdi-chevron-down
                        </v-icon>
                        <template #selection="{ item }">
                          <v-chip color="green" small>{{ item.name }}</v-chip>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Höhe:</p>

                      <v-text-field
                        v-model="item.inputs.DmgHeight"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Länge:</p>

                      <v-text-field
                        v-model="item.inputs.DmgLength"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Breite:</p>

                      <v-text-field
                        v-model="item.inputs.DmgBroad"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Beschreibung:</p>

                      <v-text-field
                        v-model="item.inputs.DmgDescription"
                        type="text"
                        outlined
                        clearable
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                  <a
                    href="#"
                    class="add-new"
                    @click.prevent="cloneNewNecroseDmg(index)"
                  >
                    <span class="mdi mdi-plus-thick custom-add-icon"></span
                    ><span>Hinzufügen</span>
                  </a>
                </v-col>
              </v-row>

              <v-row
                class="root-damage"
                v-show="treeExaminationCrown.crack"
                v-for="(item, index) in crackDamage"
                :key="'crack' + index"
                :class="viewOnly ? 'view-only' : ''"
              >
                <v-col cols="12" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-title">
                        {{ index + 1 }}. Riss:
                        <a
                          href="#"
                          v-if="crackDamage.length > 1"
                          class="remove-root-dmg"
                          @click.prevent="deleteCrackDmgContainer(index)"
                          ><span class="mdi mdi-close"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Richtung:</p>

                      <v-select
                        v-model="item.inputs.selectedDirection"
                        :items="directionItems"
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        small-chips
                        clearable
                      >
                        <v-icon slot="append" color="white">
                          mdi-chevron-down
                        </v-icon>
                        <template #selection="{ item }">
                          <v-chip color="green" small>{{ item.name }}</v-chip>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Höhe:</p>

                      <v-text-field
                        v-model="item.inputs.DmgHeight"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Länge:</p>

                      <v-text-field
                        v-model="item.inputs.DmgLength"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="2" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Breite:</p>

                      <v-text-field
                        v-model="item.inputs.DmgBroad"
                        type="number"
                        outlined
                        clearable
                        hide-details
                        append-outer-icon="cm"
                        class="number-input"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Beschreibung:</p>

                      <v-text-field
                        v-model="item.inputs.DmgDescription"
                        type="text"
                        outlined
                        clearable
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                  <a
                    href="#"
                    class="add-new"
                    @click.prevent="cloneNewCrackDmg(index)"
                  >
                    <span class="mdi mdi-plus-thick custom-add-icon"></span
                    ><span>Hinzufügen</span>
                  </a>
                </v-col>
              </v-row>

              <!-- wound -->
              <v-row
                  class="root-damage"
                  v-show="treeExaminationCrown.wound"
                  v-for="(item, index) in woundDamage"
                  :key="'wound' + index"
                  :class="viewOnly ? 'view-only' : ''"
                >
                  <v-col cols="12" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-title">
                          {{ index + 1 }}. Wunde:
                          <a
                            href="#"
                            v-if="woundDamage.length > 1"
                            class="remove-root-dmg"
                            @click.prevent="deleteWoundDmgContainer(index)"
                            ><span class="mdi mdi-close"></span
                          ></a>
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-label">Richtung:</p>

                        <v-select
                          v-model="item.inputs.selectedDirection"
                          :items="directionItems"
                          outlined
                          hide-details
                          item-text="name"
                          item-value="value"
                          small-chips
                          clearable
                        >
                          <v-icon slot="append" color="white">
                            mdi-chevron-down
                          </v-icon>
                          <template #selection="{ item }">
                            <v-chip color="green" small>{{ item.name }}</v-chip>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="2" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-label">Höhe:</p>

                        <v-text-field
                          v-model="item.inputs.DmgHeight"
                          type="number"
                          outlined
                          clearable
                          hide-details
                          append-outer-icon="m"
                          class="number-input"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="2" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-label">Länge:</p>

                        <v-text-field
                          v-model="item.inputs.DmgLength"
                          type="number"
                          outlined
                          clearable
                          hide-details
                          append-outer-icon="cm"
                          class="number-input"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="2" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-label">Breite:</p>

                        <v-text-field
                          v-model="item.inputs.DmgBroad"
                          type="text"
                          outlined
                          clearable
                          hide-details
                          append-outer-icon="cm"
                          class="number-input"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="3" class="form-element-holder">
                    <div>
                      <div class="fields">
                        <p class="custom-label">Beschreibung:</p>

                        <v-text-field
                          v-model="item.inputs.DmgDescription"
                          type="text"
                          outlined
                          clearable
                          hide-details
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-divider></v-divider>
                    <a
                      href="#"
                      class="add-new"
                      @click.prevent="cloneNewWoundDmg(index)"
                    >
                      <span class="mdi mdi-plus-thick custom-add-icon"></span
                      ><span>Hinzufügen</span>
                    </a>
                  </v-col>
              </v-row>
              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="4" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Pathogene:</p>

                      <v-select
                        v-model="treeExaminationCrown.beetleId"
                        :items="beetleItems"
                        :item-text="item => `${item.abbreviation} - ${item.name}`"
                        item-value="id"
                        outlined
                         hide-details
                        clearable
                        multiple
                        small-chips
                        persistent-hint
                        
                      >
                        <v-icon slot="append" color="white">
                          mdi-chevron-down
                        </v-icon>
                        <template #selection="{ item }">
                          <v-chip color="green" small>{{ item.name }}</v-chip>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Parasiten:</p>

                      <v-autocomplete
                        v-model="treeExaminationCrown.parasitId"
                        :items="parasitItems"
                        :item-text="item => `${item.name} - ${item.latinName} - ${item.germanName}`"
                        item-value="id"
                        outlined
                        clearable
                        hide-details
                        multiple
                        small-chips
                        persistent-hint
                        label="Suche"
                      >
                        <v-icon slot="append" color="white">
                          mdi-chevron-down
                        </v-icon>
                        <template #selection="{ item }">
                          <v-chip color="green" small>{{
                            item.germanName
                          }}</v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Holzpilze:</p>

                      <v-autocomplete
                        v-model="treeExaminationCrown.mushroomTypeId"
                        :items="mushroomItems"
                        :item-text="item => `${item.abbreviation} - ${item.latinName} - ${item.germanName}`"
                        item-value="id"
                        outlined
                        clearable
                        multiple
                        small-chips
                        hide-details
                        persistent-hint
                        label="Suche"
                      >
                        <v-icon slot="append" color="white">
                          mdi-chevron-down
                        </v-icon>
                        <template #selection="{ item }">
                          <v-chip color="green" small>{{
                            item.germanName
                          }}</v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="12" class="form-element-holder">
                  <div>
                    <p class="custom-label">Bemerkungen:</p>

                    <div class="fields">
                      <v-textarea
                        rows="2"
                        outlined
                        auto-grow
                        :counter="maxCharacters"
                        v-model="treeExaminationCrown.remarks"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="12">
                  <p class="custom-heading">
                    Ausprägung Wundrand:
                  </p>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.good"
                        hide-details
                        label="gut"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.diffuse"
                        hide-details
                        label="diffus"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.noReaction"
                        hide-details
                        label="keine Reaktion"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="3" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.dyingBack"
                        hide-details
                        label="zurücksterbend"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="4" class="form-element-holder">
                  <div>
                    <p class="custom-label">Beschreibung:</p>

                    <div class="fields">
                      <v-textarea
                        rows="2"
                        outlined
                        auto-grow
                        :counter="maxCharacters"
                        v-model="treeExaminationCrown.description"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <v-checkbox
                        v-model="treeExaminationCrown.unchanged"
                        hide-details
                        label="unverändert"
                        class="custom-checkbox"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="4" class="form-element-holder">
                  <div>
                    <p class="custom-label">Veränderung:</p>

                    <div class="fields">
                      <v-textarea
                        rows="2"
                        outlined
                        auto-grow
                        :counter="maxCharacters"
                        v-model="treeExaminationCrown.change"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row :class="viewOnly ? 'view-only' : ''">
                <v-col cols="6" class="form-element-holder">
                  <div>
                    <div class="fields">
                      <p class="custom-label">Foto:</p>
                    </div>

                    <v-row
                      class="preview-images"
                      v-if="treeExaminationCrown.crownImages.length > 0"
                    >
                      <v-col
                        cols="2"
                        v-for="(image,
                        index) in treeExaminationCrown.crownImages"
                        :key="index"
                      >
                        <v-img
                          :src="getImageSrc(image)"
                          class="custom-preview-image"
                          cover
                          aspect-ratio="1"
                        >
                          <v-btn
                            class="remove-image-preview"
                            tile
                            width="20"
                            color="error"
                            @click="removeTrunkBaseImage(index)"
                          >
                            <v-icon size="16">mdi mdi-close</v-icon>
                          </v-btn>
                        </v-img>
                        <p >{{ image.fileName }}</p>
                      </v-col>
                    </v-row>
                    <div class="upload-buttons">
                      <input
                      type="file"
                      accept="image/*"
                      capture="camera"
                      @change="handleFileChange"
                      ref="fileInput"
                      multiple
                      style="display: none;"
                    />
                    <div class="custom-file-input" @click="openFileInput">
                      <img
                        src="../../assets/images/icons/upload-gallery-image.svg"
                        alt="Upload Icon"
                        class="custom-upload-icon"
                      />
                    </div>
                      <input
                      type="file"
                      accept="image/*"
                      capture="camera"
                      @change="handleFileChange"
                      multiple
                      ref="fileInput"
                      style="display: none;"
                    />
                    <div class="custom-file-input" @click="openFileInput">
                      <img
                        src="../../assets/images/icons/upload-camera-image.svg"
                        alt="Upload Icon"
                        class="custom-upload-icon"
                      />
                    </div>
                  </div>
                    
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row :class="viewOnly ? 'view-only' : ''">
      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Gesundheit:</p>

            <v-select
              v-model="treeExaminationCrown.healthId"
              :items="healthItems"
              item-text="description"
              item-value="id"
              outlined
              hide-details
              clearable
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
            </v-select>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Schädigung:</p>
            <v-select
              v-model="treeExaminationCrown.injury"
              :items="injuryItems"
              outlined
              hide-details
              clearable
              append-outer-icon="%"
              class="number-input"
              item-text="name"
              item-value="value"
            ></v-select>
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Verkehrssicherheit:*</p>

            <v-select
              v-model="treeExaminationCrown.roadSafetyId"
              :items="roadSafetyItems"
              item-text="description"
              item-value="id"
              hide-details
              outlined
              clearable
              :rules="[(v) => !!v || 'This field is required']"
              @change="updateRoadSafetyId"
              :class="treeExaminationCrown.roadSafetyId ? '' : 'error--text'"
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
            </v-select>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row :class="viewOnly ? 'view-only' : ''">
      <v-col cols="4" class="form-element-holder">
        <div>
          <div class="fields">
            <v-checkbox
              v-model="treeExaminationCrown.habitatTree"
              hide-details
              label="Habitatbaum"
              class="custom-checkbox"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row :class="viewOnly ? 'view-only' : ''">
      <v-col cols="3" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Käfer:</p>

            <v-select
              v-model="treeExaminationCrown.beetlesId"
              :items="beetleItems"
              :item-text="item => `${item.abbreviation} - ${item.name}`"
              item-value="id"
              hide-details
              outlined
              clearable
              multiple
              small-chips
              persistent-hint
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
              <template #selection="{ item }">
                <v-chip color="green" small>{{ item.name }}</v-chip>
              </template>
            </v-select>
          </div>
        </div>
      </v-col>

      <v-col cols="3" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Insekten:</p>

            <v-select
              v-model="treeExaminationCrown.insectsId"
              :items="insectsItems"
              :item-text="item => `${item.abbreviation} - ${item.germanName}`"
              item-value="id"
              outlined
              clearable
              hide-details
              multiple
              small-chips
              persistent-hint
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
              <template #selection="{ item }">
                <v-chip color="green" small>{{ item.germanName }}</v-chip>
              </template>
            </v-select>
          </div>
        </div>
      </v-col>

      <v-col cols="3" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Vogel:</p>

            <v-select
              v-model="treeExaminationCrown.birdId"
              :items="birdsItems"
              :item-text="item => `${item.abbreviation} - ${item.name}`"
              item-value="id"
              outlined
              clearable
              hide-details
              multiple
              small-chips
              persistent-hint
            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
              <template #selection="{ item }">
                <v-chip color="green" small>{{ item.name }}</v-chip>
              </template>
            </v-select>
          </div>
        </div>
      </v-col>

      <v-col cols="3" class="form-element-holder">
        <div>
          <div class="fields">
            <p class="custom-label">Säugetiere:</p>

            <v-select
              v-model="treeExaminationCrown.mammalsId"
              :items="mamalsItems"
              :item-text="item => `${item.abbreviation} - ${item.name}`"
              item-value="id"
              outlined
              hide-details
              clearable
              multiple
              small-chips
              persistent-hint

            >
              <v-icon slot="append" color="white">
                mdi-chevron-down
              </v-icon>
              <template #selection="{ item }">
                <v-chip color="green" small>{{ item.name }}</v-chip>
              </template>
            </v-select>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import DamageAreaTypes from "@/consts/damageAreaTypes";
import DirectionTypes from "@/consts/directionTypes";
import InjuryTypes from "@/consts/injuryTypes";
export default {
  props: ["initialData", "viewOnly", "atLeastOne"],
  data() {
    return {
      maxCharacters: 150,
      isLoading: true,
      hasError: false,
      directionItems: DirectionTypes,
      injuryItems: InjuryTypes,
      treeExaminationCrown: {
        locationId: 0,
        majorWounds: 0,
        hollows: 0,
        criticalForks: 0,
        crownAnchorage: 0,
        rigid: 0,
        branchFractures: 0,
        deadBranches: 0,
        peripheral: 0,
        secondaryDriveShare: 0,
        shootGrowthLength: 0,
        prematureLeafFall: 0,
        defoliation: 0,
        discoloration: false,
        branchByBranch: 0,
        crownLength: 0,
        secondaryCrown: true,

        healthId: 0,
        injury: 0,
        roadSafetyId: 0,
        habitatTree: false,
        beetlesId: [],
        insectsId: [],
        birdId: [],
        mammalsId: [],

        /* new from here */
        necrosis: false,
        wound: false,
        crack: false,
        fungalFruitingBody: false,
        drillHole: false,
        beetleId: [],
        parasitId: [],
        mushroomTypeId: [],
        remarks: "",
        good: false,
        diffuse: false,
        noReaction: false,
        dyingBack: false,
        description: "",
        unchanged: false,
        change: "",
        crownNecrosisDamagedAreaDetails: [],
        crownCrackDamagedAreaDetails: [],
        crownWoundDamagedAreaDetails: [],
        crownImages: []
      },
      rules: {
        numericRule: [
          v => {
            if (!v) return true; // Accept empty input
            return /^\d+$/.test(v) || "Please enter only numbers"; // Rule to accept only numbers
          }
        ]
      },
      necroseDamage: [
        {
          inputs: {
            selectedDirection: null,
            DmgHeight: null,
            DmgLength: null,
            DmgBroad: null,
            DmgDescription: null
          },
          classes: [
            {
              class: "root-damage"
            }
          ],
          isDuplicatedWith: null
        }
      ],

      crackDamage: [
        {
          inputs: {
            selectedDirection: null,
            DmgHeight: null,
            DmgLength: null,
            DmgBroad: null,
            DmgDescription: null
          },
          classes: [
            {
              class: "root-damage"
            }
          ],
          isDuplicatedWith: null
        }
      ],
      woundDamage: [
        {
          inputs: {
            selectedDirection: null,
            DmgHeight: null,
            DmgLength: null,
            DmgBroad: null,
            DmgDescription: null
          },
          classes: [
            {
              class: "root-damage"
            }
          ],
          isDuplicatedWith: null
        }
      ],
      locationsItems: [],
      healthItems: [],
      roadSafetyItems: [],
      beetleItems: [],
      insectsItems: [],
      birdsItems: [],
      mamalsItems: [],
      parasitItems: [],
      mushroomItems: [],
      selectedCrownImages: [],
    };
  },
  mounted() {
    this.fetchLocations();
    this.fetchHealthOptions();
    this.fetchRoadSafetyOptions();
    this.fetchBeetleOptions();
    this.fetchInsectsOptions();
    this.fetchBirdsOptions();
    this.fetchMammalsOptions();
    this.fetchParasitOptions();
    this.fetchMushroomOptions();


    this.treeExaminationCrown = { ...this.initialData };

    /* prepopulate crackDamage if there are on initial data */
    if (this.treeExaminationCrown.crownCrackDamagedAreaDetails.length > 0) {
      /* make it empty then popullate */
      this.crackDamage = [];
      this.treeExaminationCrown.crownCrackDamagedAreaDetails.forEach(
        (element, index) => {
          const newItem = {
            inputs: {
              selectedDirection: element.directionTypeId,
              DmgHeight: element.height,
              DmgLength: element.length,
              DmgBroad: element.width,
              DmgDescription: element.description
            },
            classes: [
              {
                class: "root-damage"
              }
            ],
            isDuplicatedWith: null
          };

          this.crackDamage.push(newItem);
        }
      );
    }

    /* prepopulate necroseDamage if there are on initial data */
    if (this.treeExaminationCrown.crownNecrosisDamagedAreaDetails.length > 0) {
      /* make it empty then popullate */
      this.necroseDamage = [];
      this.treeExaminationCrown.crownNecrosisDamagedAreaDetails.forEach(
        (element, index) => {
          const newItem = {
            inputs: {
              selectedDirection: element.directionTypeId,
              DmgHeight: element.height,
              DmgLength: element.length,
              DmgBroad: element.width,
              DmgDescription: element.description
            },
            classes: [
              {
                class: "root-damage"
              }
            ],
            isDuplicatedWith: null
          };

          this.necroseDamage.push(newItem);
        }
      );
    }
    if (this.treeExaminationCrown.crownWoundDamagedAreaDetails.length > 0) {
      /* make it empty then popullate */
      this.woundDamage = [];
      this.treeExaminationCrown.crownWoundDamagedAreaDetails.forEach(
        (element, index) => {
          const newItem = {
            inputs: {
              selectedDirection: element.directionTypeId,
              DmgHeight: element.height,
              DmgLength: element.length,
              DmgBroad: element.width,
              DmgDescription: element.description
            },
            classes: [
              {
                class: "root-damage"
              }
            ],
            isDuplicatedWith: null
          };

          this.woundDamage.push(newItem);
        }
      );
    }
  },
  watch: {
    'treeExaminationCrown.locationId': function(newValue) {
      if (newValue === null) {
        this.treeExaminationCrown.locationId = 0;
      }
    },
    'treeExaminationCrown.healthId': function(newValue) {
      if (newValue === null) {
        this.treeExaminationCrown.healthId = 0;
      }
    },
    'treeExaminationCrown.roadSafetyId': function(newValue) {
      if (newValue === null) {
        this.treeExaminationCrown.roadSafetyId = 0;
      }
    },
    checkAtLeastOneSelected: {
      handler: function(val, oldVal) {
        if (val) {
          this.$emit("checkbox-updated", val);
        }
      },
    },
   
    objekti: {
      handler: function(val, oldVal) {
        if (val) {
          this.$emit("kroneAccordion", val);
        }
      },
      deep: true
    },
    necroseDamage: {
      handler: function(newVal) {
        this.updateCrownDamagedAreaNecrose(newVal);
      },
      deep: true
    },
    crackDamage: {
      handler: function(newVal) {
        this.updateCrownDamagedAreaCrack(newVal);
      },
      deep: true
    },
    woundDamage:{
      handler: function(newVal) {
        this.updateCrownDamagedAreaWound(newVal);
      },
      deep: true
    },
    "treeExaminationCrown.necrosis": function(newValue, oldValue) {
      if (newValue === false) {
        /* remove all necroseDamage items except index 0 */
        this.necroseDamage = this.necroseDamage.slice(0, 1);

        /* reset it */
        this.necroseDamage[0].inputs = {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        };

        /* reset original object to empty array using nextTick */
        this.$nextTick(() => {
          this.treeExaminationCrown.crownNecrosisDamagedAreaDetails = [];
        });
      }
    },

    "treeExaminationCrown.crack": function(newValue, oldValue) {
      if (newValue === false) {
        /* remove all necroseDamage items except index 0 */
        this.crackDamage = this.crackDamage.slice(0, 1);

        /* reset it */
        this.crackDamage[0].inputs = {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        };

        /* reset original object to empty array using nextTick */
        this.$nextTick(() => {
          this.treeExaminationCrown.crownCrackDamagedAreaDetails = [];
        });
      }
    },
    "treeExaminationCrown.wound": function(newValue, oldValue) {
      if (newValue === false) {
        /* remove all necroseDamage items except index 0 */
        this.woundDamage = this.woundDamage.slice(0, 1);

        /* reset it */
        this.woundDamage[0].inputs = {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        };

        /* reset original object to empty array using nextTick */
        this.$nextTick(() => {
          this.treeExaminationCrown.crownWoundDamagedAreaDetails = [];
        });
      }
    }
  },
  computed: {
    hasTrueValue() {
      const keysToCheck = ['majorWounds', 'hollows', 'criticalForks', 'crownAnchorage', 'rigid', 'branchFractures', 'deadBranches', 'peripheral', 'secondaryDriveShare', 'shootGrowthLength', 'prematureLeafFall', 'defoliation', 'branchByBranch', 'crownLength'];

      for (const key of keysToCheck) {
        if (this.treeExaminationCrown[key] !== null) {
          return true;
        }
      }
      // Check if any value is true or locationId is not 0
      for (const key in this.treeExaminationCrown) {
        if (this.treeExaminationCrown[key] === true || this.treeExaminationCrown.locationId !== 0) {
          return true;
        }
      }

      return false;
    },
    checkAtLeastOneSelected(v) {
      if (!this.treeExaminationCrown.noVisibleDamage && !this.hasTrueValue ) {
      return 'You must select at least one option!';
      }
      return true;
    },
    objekti() {
      return this.treeExaminationCrown;
    }
  },
  methods: {
    async fetchLocations() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Location/GetAll`
        );


        this.locationsItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchHealthOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Health/GetAll`
        );

        this.healthItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchRoadSafetyOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/RoadSafety/GetAll`
        );

        this.roadSafetyItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchBeetleOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Beetle/GetAll`
        );


        this.beetleItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchInsectsOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/InsectType/GetAll`
        );


        this.insectsItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchBirdsOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Bird/GetAll`
        );

        this.birdsItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchMammalsOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Mammals/GetAll`
        );

        this.mamalsItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchParasitOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Parasit/GetAll`
        );

        this.parasitItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchMushroomOptions() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/MushroomType/GetAll`
        );


        this.mushroomItems = response.data;

        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    cloneNewNecroseDmg(index) {


      const newNecroseDmg = {
        damagedAreaTypeId: DamageAreaTypes.Crown_Necrosis,
        directionTypeId: this.necroseDamage[index].inputs.selectedDirection || 0,
        height: this.necroseDamage[index].inputs.DmgHeight,
        length: this.necroseDamage[index].inputs.DmgLength,
        width: this.necroseDamage[index].inputs.DmgBroad,
        description: this.necroseDamage[index].inputs.DmgDescription
      };

      this.treeExaminationCrown.crownNecrosisDamagedAreaDetails.push(
        newNecroseDmg
      );

      this.necroseDamage.splice(index + 1, 0, {
        inputs: {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        },
        classes: [
          {
            class: "root-damage"
          }
        ],
        isDuplicatedWith: null
      });
    },

    cloneNewCrackDmg(index) {

      /* populate trunkBaseDamagedAreaDetails */

      const newCrackDmg = {
        damagedAreaTypeId: DamageAreaTypes.Crown_Crack,
        directionTypeId: this.crackDamage[index].inputs.selectedDirection || 0,
        height: this.crackDamage[index].inputs.DmgHeight,
        length: this.crackDamage[index].inputs.DmgLength,
        width: this.crackDamage[index].inputs.DmgBroad,
        description: this.crackDamage[index].inputs.DmgDescription
      };

      this.treeExaminationCrown.crownCrackDamagedAreaDetails.push(newCrackDmg);

      this.crackDamage.splice(index + 1, 0, {
        inputs: {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        },
        classes: [
          {
            class: "root-damage"
          }
        ],
        isDuplicatedWith: null
      });
    },
    cloneNewWoundDmg(index) {

      /* populate trunkBaseWoundAreaDetails */

      const newWoundDmg = {
        damagedAreaTypeId: DamageAreaTypes.Crown_Wound,
        directionTypeId: this.woundDamage[index].inputs.selectedDirection || 0,
        height: this.woundDamage[index].inputs.DmgHeight,
        length: this.woundDamage[index].inputs.DmgLength,
        width: this.woundDamage[index].inputs.DmgBroad,
        description: this.woundDamage[index].inputs.DmgDescription
      };
      this.treeExaminationCrown.crownWoundDamagedAreaDetails.push(newWoundDmg);

      this.woundDamage.splice(index + 1, 0, {
        inputs: {
          selectedDirection: null,
          DmgHeight: null,
          DmgLength: null,
          DmgBroad: null,
          DmgDescription: null
        },
        classes: [
          {
            class: "root-damage"
          }
        ],
        isDuplicatedWith: null
      });
    },

    deleteNecroseDmgContainer(index) {
      this.necroseDamage = this.necroseDamage.filter((item, i) => i !== index);
    },

    deleteCrackDmgContainer(index) {
      this.crackDamage = this.crackDamage.filter((item, i) => i !== index);
    },
    deleteWoundDmgContainer(index) {
      this.woundDamage = this.woundDamage.filter((item, i) => i !== index);
    },

    updateCrownDamagedAreaNecrose(necroseDamage) {
      /* this function prepares data on trunkBaseDamagedAreaDetails handles removing of item aswell */
      this.treeExaminationCrown.crownNecrosisDamagedAreaDetails = necroseDamage.map(
        item => {
          return {
            damagedAreaTypeId: DamageAreaTypes.Crown_Necrosis,
            directionTypeId: item.inputs.selectedDirection || 0,
            height: item.inputs.DmgHeight,
            length: item.inputs.DmgLength,
            width: item.inputs.DmgBroad,
            description: item.inputs.DmgDescription
          };
        }
      );
    },

    updateCrownDamagedAreaCrack(crackDamage) {
      this.treeExaminationCrown.crownCrackDamagedAreaDetails = crackDamage.map(
        item => {
          return {
            damagedAreaTypeId: DamageAreaTypes.Crown_Crack,
            directionTypeId: item.inputs.selectedDirection || 0,
            height: item.inputs.DmgHeight,
            length: item.inputs.DmgLength,
            width: item.inputs.DmgBroad,
            description: item.inputs.DmgDescription
          };
        }
      );
    },
    updateCrownDamagedAreaWound(woundDamage) {
      this.treeExaminationCrown.crownWoundDamagedAreaDetails = woundDamage.map(
        item => {
          return {
            damagedAreaTypeId: DamageAreaTypes.Crown_Wound,
            directionTypeId: item.inputs.selectedDirection || 0,
            height: item.inputs.DmgHeight,
            length: item.inputs.DmgLength,
            width: item.inputs.DmgBroad,
            description: item.inputs.DmgDescription
          };
        }
      );
    },
    updateRoadSafetyId(value) {
    this.$emit('road-safety-updated', value);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          const base64String = e.target.result.split(",")[1]; // Extract the base64 string
          const fileData = {
            document: base64String,
            nameBase64: base64String,
            fileName: file.name,
            fileExtension: file.name.split(".").pop()
          };

          this.treeExaminationCrown.crownImages.push(fileData);
        };
      }

      this.selectedRootDamageImages = []; // Clear the selected files after uploading
    },
    removeTrunkBaseImage(index) {
      this.treeExaminationCrown.crownImages.splice(index, 1);
      this.selectedCrownImages = [];
    },

    getImageSrc(image) {
      if(image.nameBase64 != null){
         return  `data:image/${image.fileName};base64,${image.nameBase64}`;
      }
      else{
         return `data:image/${image.fileName};base64,${image.document}`;
      }
    }
  }
};
</script>

<style>
  .v-messages.error--text {
    color: red; /* Sets the error text color to red */
  }
  .v-text-field.error--text .v-text-field__details {
    border-color: red; /* Sets the border color to red when error */
  }</style>
