<template>
    <v-container fluid d-flex>
        <div class="left-side">
            <img src="../../../../assets/images/arrow-right-white.png" alt="arrow-right-white" @click="closeViewMap" />
            <h2 @click="closeViewMap">Karte schließen</h2>
        </div>
        <div class="map">

            <l-map :maxZoom="maxZoom" :zoom="zoom" :minZoom="minZoom" :key="mapKey" :center="center" @click="onMapClick"
                ref="map" @ready="onMapReady">
                <l-tile-layer :options="{ maxZoom: maxZoom }" :url="tileLayerUrl"
                    :attribution="currentLayer.attribution"></l-tile-layer>
                    <l-control class="layer-control-inventory-map">
                        <div class="change-layer">
                            <select class="layer-select" v-model="selectedOption" @change="updateTileLayerUrl">
                                <option class="item-select" v-for="(option, index) in options" :key="index" :value="option">
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                    </l-control>
                <l-control class="topright">
                    <div class="undo-redo-clean dark-bg">
                        <button class="btn close" @click="closeViewMap">
                            <img class="close-btn-img" src="../../../../assets/images/close_white_border.png"
                                alt="location" width="32px" height="32px" />
                            <span></span>
                        </button>
                    </div>
                </l-control>
                <l-control-zoom />
                <l-control class="my-controls">
                    <button class="location" @click="goToMyLocation">
                        <img src="../../../../assets/images/my-location.png" alt="location" />
                    </button>
                </l-control>
                <l-layer-group>
                    <l-polygon v-for="(swiss,index) in switzerlandCities" :key="`city-${index}`" :lat-lngs="[swiss.coordinates]"> 
                        <l-tooltip class="citites-tooltip" :options="{permanent: true, direction: 'center'}">{{ swiss.city }}</l-tooltip>
                    </l-polygon>
                    <l-polygon v-for="(zone, index) in AreaRegions" :key="`area${index}`" :lat-lngs="zone.coordinates"
                        :color="zone.colorTypeId" :fillColor="zone.colorTypeId" @click="onArea(zone, $event)">
                        <l-popup ref="popup" :close-button="false" :options="popupOptions" v-if="showPopup">
                            <div class="popup-content">
                                <div class="title">
                                    <img src="../../../../assets/images/map-icon-viewed.png" alt="map-icon" width="27px"
                                        height="25px" />
                                    <h2 v-if="inventarTitle">{{ inventarTitle }}</h2>
                                    <h2 v-else>-</h2>
                                </div>

                                <div class="informations" v-if="areaInfo">
                                    <div class="popup-description">
                                        <span>Baum ID:</span>
                                        <span> {{ treeInfo.name }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Latein:</span>
                                        <span> {{ treeInfo.treeTypeLatinName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Deutsch:</span>
                                        <span> {{ treeInfo.treeTypeGermanName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Baumhöhe(m):</span>
                                        <span> {{ lastTechnicalDataInfoHeight }}</span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Kronen Ø:</span>
                                        <span> {{ lastTechnicalDataInfoCrown }}</span>
                                    </div>

                                    <div v-for="task in treeInfo.mapData" :key="task.taskId" class="popup-description">
                                        <span>{{ task.worktype }}:</span>
                                        <span class="date-link" v-if="!task.isTreeExamination"
                                            @click.prevent="goToTaskProfil(task.taskId, task.taskDate)">{{ task.taskDate
                                            }}</span>
                                        <span class="date-link" v-else-if="task.isTreeExamination"
                                            @click.prevent="goToBaumControll(task.taskId, task.treeExaminationId)">{{
                task.taskDate }}</span>
                                        <span v-else> - </span>
                                    </div>

                                </div>
                                <div v-else>Loading...</div>
                                <div class="footer-links">
                                    <button class="btn" @click.prevent="printMap">
                                        Karte Drucken
                                    </button>
                                </div>
                            </div>
                        </l-popup>
                        <l-tooltip :options="{ permanent: true, direction: 'center' }">area - {{ zone.areaId
                            }}</l-tooltip>
                    </l-polygon>
                    <l-polygon v-if="zoom >= 16" v-for="(zone, index) in treeRegions" :key="`tree${index}`"
                        :lat-lngs="zone.coordinates" :color="zone.colorTypeId"
                        :options="getPolygonOptions(zone.crownDiameter, zone.treeId)" @click="onTree(zone, $event)"
                        style="z-index:999">
                        <l-popup ref="popup" :close-button="false" :options="popupOptions" v-if="showPopup">
                            <div class="popup-content">
                                <div class="title">
                                    <img src="../../../../assets/images/map-icon-viewed.png" alt="map-icon" width="27px"
                                        height="25px" />
                                    <h2 v-if="inventarTitle">{{ inventarTitle }}</h2>
                                    <h2 v-else>-</h2>
                                </div>

                                <div class="informations" v-if="treeInfo">
                                    <div class="popup-description">
                                        <span>Baum ID:</span>
                                        <span> {{ treeInfo.name }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Latein:</span>
                                        <span> {{ treeInfo.treeTypeLatinName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Deutsch:</span>
                                        <span> {{ treeInfo.treeTypeGermanName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Baumhöhe(m):</span>
                                        <span> {{ lastTechnicalDataInfoHeight }}</span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Kronen Ø:</span>
                                        <span> {{ lastTechnicalDataInfoCrown }}</span>
                                    </div>

                                    <div v-for="task in treeInfo.mapData" :key="task.taskId" class="popup-description">
                                        <span>{{ task.worktype }}:</span>
                                        <span class="date-link" v-if="!task.isTreeExamination"
                                            @click.prevent="goToTaskProfil(task.taskId, task.taskDate)">{{ task.taskDate
                                            }}</span>
                                        <span class="date-link" v-else-if="task.isTreeExamination"
                                            @click.prevent="goToBaumControll(task.taskId, task.treeExaminationId)">{{
                task.taskDate }} </span>
                                        <span v-else> - </span>
                                    </div>
                                </div>
                                <div v-else>loading...</div>

                                <div class="footer-links">
                                    <button class="btn" @click.prevent="printMap">
                                        Karte Drucken
                                    </button>
                                </div>
                            </div>
                        </l-popup>
                        <l-tooltip 
                            :options="{ permanent: true, direction: 'center' }" 
                            :class="selectedOption === 'Cadastral' ? 'custom-polygon-cadastral' : 'custom-polygon'">
                            {{ zone.name }}
                            </l-tooltip>
                    </l-polygon>
                    <l-polygon v-for="(zone, index) in mapRegions" :ikey="`inventar${index}`"
                        :lat-lngs="zone.coordinates" :color="color" :options="polygonOptions" style="z-index:0">
                    </l-polygon>

                    <template v-for="(point, index) in markers">
                        <l-marker :lat-lng="point" :draggable="false" class="markerTree">
                            <l-icon 
                            :icon-size="[25, 41]" 
                            :icon-anchor="[13, 41]" 
                            :icon-url="selectedOption === 'Cadastral' 
                                ? require('@/assets/images/cadastral-pin.svg') 
                                : require('@/assets/images/pin-map.svg')">
                            </l-icon>
                        </l-marker>
                    </template>
                </l-layer-group>
            </l-map>
        </div>
        <CustomLoader v-show="isLoading" />

    </v-container>
</template>


<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    LMap,
    LTileLayer,
    LPolygon,
    LTooltip,
    LLayerGroup,
    LIcon,
    LPopup,
    LDrawToolbar,
    LControlZoom,
    LControl,
    LMarker,
    LPolyline
} from "vue2-leaflet";
import axios from "axios";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import {
    Icon
} from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw";
import * as L from 'leaflet'
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import CustomLoader from "../../../../components/custom_components/CustomLoader.vue";
import moment from 'moment';
export default {
    components: {
        LMap,
        LTileLayer,
        LPolygon,
        LTooltip,
        LLayerGroup,
        LIcon,
        LPopup,
        LDrawToolbar,
        LControlZoom,
        LControl,
        LMarker,
        LPolyline,
        CustomLoader
    },
    props: ['centerr', 'coordinates', 'whichForm'],
    data() {
        return {
            centerMapDynamically: null,
            minZoom: 12,
            maxZoom: 20,
            mapKey: 0,
            center: [51.49, -0.05], // set your initial center
            apikey: "AIzaSyC27AbpxpWs0UKZEzUcq-qQ4zDcI_D4jKo",
            tileLayerUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
            currentLayer: {
                name: "",
                baseUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg', // Replace with your WMS service URL
                cadastarUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.kantone.cadastralwebmap-farbe/default/current/3857/{z}/{x}/{y}.png', // Replace with your WMS service URL
                apikey: "kOlaYPuPlEZLGataPQ1r",
            },
            selectedOption: 'Standard',
            options: [
                'Standard',
                'Cadastral'
            ],
            clickLatLngs: [],
            isCreatingPolygon: false,
            polygonPoints: [],
            redoPolygonPoints: [],
            mapRegions: [],
            AreaRegions: [],
            treeRegions: [],
            polygonOptions: {
                fillColor: "transparent",
                color: "#494949",
                weight: 3,
                opacity: 0.6,
                fillOpacity: 0.6,
            },
            polygonOptionsArea: {
                weight: 1,
                border: "3px solid #A4A4A4",
                opacity: 0.6,
                fillOpacity: 0.6,
            },
            polygonOptionsTree: {
                weight: 200,
                border: "3px solid #A4A4A4",
                opacity: 0.6,
                fillOpacity: 0.6
            },
            polylineOptions: {},
            popupOptions: {
                closeButton: false,
                autoClose: false,
                autoOpenPopup: true,
            },
            color: "#A4A4A4",
            layers: [{
                name: "Regions",
                active: true,
                leafletLayer: null,
                layer: L.layerGroup(),
            },],
            zoom: 0, // Initial zoom
            markers: [],
            markerAdded: false,
            formType: "",
            treeInfo: null,
            areaInfo: null,
            clickedZoneId: null,
            openTreePopup: false,
            openAreaPopup: false,
            skip: 0,
            take: 400,
            prevRoute: null,
            snapshotOptions: {
                hideElementsWithSelectors: [
                    ".leaflet-control-container",
                    ".leaflet-dont-include-pane",
                    "#snapshot-button"
                ],
                hidden: true
            },
            screenshotter: null,
            showPopup: true,
            isLoading: false,
            treeTasks: [],
            markers: []
        };
    },
    computed: {
        ...mapGetters(["mapRegionData", "error", "loggedInUser", "inventarId", "inventarCoordinates", "getTreeItem", "getAreaItem", "inventarTitle","switzerlandCities"]),
        lastTechnicalDataInfoHeight() {
            // Assuming treeInfo.technicalDatas is an array
            if (this.treeInfo.technicalDatas.length > 0) {
                const lastIndex = this.treeInfo.technicalDatas.length - 1;
                const lastObject = this.treeInfo.technicalDatas[lastIndex].treeHeight;
                return lastObject
            } else {
                return this.treeInfo.technicalDatas[0].height
            }
        },
        lastTechnicalDataInfoCrown() {
            // Assuming treeInfo.technicalDatas is an array
            if (this.treeInfo.technicalDatas.length > 0) {
                const lastIndex = this.treeInfo.technicalDatas.length - 1;
                const lastObject = this.treeInfo.technicalDatas[lastIndex].crownDiameter;
                return lastObject
            } else {
                return this.treeInfo.technicalDatas[0].crownDiameter
            }
        },
        checkForm() {
            if (this.whichForm == "bereich") {
                this.markers = []
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    methods: {
        async updateTileLayerUrl() {
            this.skip = 0
            this.take = 300
            this.AreaRegions = []
            this.treeRegions = []
            this.setMapDataAreaRegions()
            this.setMapDataTreeRegions();

            if (this.selectedOption === 'Cadastral') {
                this.tileLayerUrl = this.currentLayer.cadastarUrl;
                // if (this.prevRoute.name == "Pflegeplan" || this.prevRoute.name == "Baumkontrolle") {
                //     this.centerMapOnZoneFromInFromTasksORBaumControll(this.treeTasks)
                // }else {
                //     this.centerMapOnZoneFromIn(this.treeRegions)
                // }
            } else {

                this.tileLayerUrl = this.currentLayer.baseUrl;
                // if (this.prevRoute.name == "Pflegeplan" || this.prevRoute.name == "Baumkontrolle") {
                //     this.centerMapOnZoneFromInFromTasksORBaumControll(this.treeTasks)
                // }else {
                //     this.centerMapOnZoneFromIn(this.treeRegions)
                // }
            }

            this.mapKey++;
        },
        async printMap() {
            this.showPopup = false
            this.isLoading = true
            setTimeout(() => {
                try {

                    // Get the Leaflet map instance from the Vue component
                    const map = this.$refs.map.mapObject;

                    // Set up snapshotter
                    const snapshotOptions = {
                        hideElementsWithSelectors: [
                            ".leaflet-control-container",
                            "#snapshot-button",
                        ],
                        hidden: true,
                    };

                    this.screenshotter = new SimpleMapScreenshoter(snapshotOptions);
                    this.screenshotter.addTo(map);

                } catch (error) {
                    console.error(error.message);
                }
            }, 3000)

            setTimeout(() => {
                this.takeScreenShot();

            }, 4000)
        },


        takeScreenShot() {
            // Ensure that the screenshotter is initialized
            if (this.screenshotter) {
                // Set up screenshot function
                this.screenshotter
                    .takeScreen("image")
                    .then(async (image) => {
                        // ... (rest of your code)
                        console.log("::::image", image);
                        const formattedDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

                        const link = document.createElement("a");
                        link.download = `${formattedDateTime}-${this.inventarTitle}-screenshot.png`;
                        link.href = image;
                        link.click();
                        this.isLoading = false
                        this.showPopup = true

                    })
                    .catch((e) => {
                        alert(e.toString());
                    });
            } else {
                console.error("SimpleMapScreenshoter is not initialized");
            }
        },
        closeViewMap() {

            this.$router.back();

        },
        goToBaumControll(taskId, treeExaminationId) {
            this.$router.push({
                name: "BaumKontrollDetails",
                params: {
                    taskId: taskId,
                    treeId: this.treeInfo.id,
                    treeName: this.treeInfo.treeName,
                    treeExaminationId: treeExaminationId,
                    isEditBK: treeExaminationId != 0,
                    isSealed: false,
                    treeType: this.treeInfo.treeType,
                    viewOnly: false
                }
            })
        },
        goToTaskProfil(taskId, taskDate) {
            const year = taskDate.split('-')[2];
            this.$router.push({
                name: "Pflegeplan Eintrag",
                params: { year, taskId }
            })
        },
        getPolygonOptions(size, id) {
            let scaledSize = 25;

            switch (true) {
                case size > 18:
                    scaledSize = size * 2;
                    break;
                case size >= 12 && size <= 18:
                    scaledSize = size * 3;
                    break;
                case size >= 8 && size < 12:
                    scaledSize = size * 4;
                    break;
                case size >= 5 && size < 8:
                    scaledSize = size * 6;
                    break;
                case size >= 2 && size < 5:
                    scaledSize = size * 10;
                    break;
                default:
                    scaledSize = size * 20;
            }

            const strokeWidth = id === this.clickedZoneId ? 3 : scaledSize;

            return {
                fillColor: this.color,
                color: this.color,
                weight: strokeWidth, // Bind weight property to a dynamic value (e.g., size)
                border: `${3}px solid ${this.color}`, // Bind border property to a dynamic value
                opacity: 0.6,
                fillOpacity: 0.6,
                className: "custom-polygon",
            };
        },
        onArea(zone, event) {
            this.clickedZoneId = zone.id;
            this.openAreaPopup = true;
            axios
                .get(`https://tilia-ch.rrota.org/api/Tree/GetArea/${this.inventarId}/${zone.areaId}`)
                .then(response => {

                    this.areaInfo = response.data

                })
                .catch(error => {
                    console.log("error", error);
                })
        },
        onTree(zone, event) {
            this.clickedZoneId = zone.treeId;
            this.openTreePopup = true

            axios
                .get(`https://tilia-ch.rrota.org/api/Tree/GetTree/${zone.inventoryId}/${zone.treeId}`)
                .then(response => {
                    response.data.mapData.forEach(item => {
                        item.taskDate = moment(item.taskDate).format("DD-MM-YYYY");
                    });
                    response.data.mapData.sort((a, b) => {
                    return moment(a.taskDate, "DD-MM-YYYY").diff(moment(b.taskDate, "DD-MM-YYYY"));
                     });
                    this.treeInfo = response.data;
                })
                .catch(error => {
                    console.log("error", error);
                })


        },

        onMarkerDragEnd(event, index) {
            // this.$set(this.polygonPoints, index, latlng);
            const latlng = event.target.getLatLng();
            const newLat = latlng.lat;
            const newLng = latlng.lng;
            this.$set(this.polygonPoints, index, [newLat, newLng]);
            this.savePolygon();
        },
        goToMyLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const latlng = [position.coords.latitude, position.coords.longitude];
                    this.$refs.map.mapObject.setView(latlng, 18); // Set the map view to your location with zoom level 15
                });
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        },

        onMapReady() {
            this.mapReady = true;


            // this.centerMapOnZoneFromIn();

        },
        centerMapOnZone() {
            // Find the average latitude and longitude of all points in the zone

            if (this.centerMapDynamically != null) {
                const latlngBounds = L.latLngBounds(this.centerMapDynamically);
                const center = latlngBounds.getCenter();

                // Use Leaflet's fitBounds method to set the map view to display the entire zone
                this.$refs.map.mapObject.fitBounds(latlngBounds);

                // Update the component's center and zoom properties to reflect the new map view
                this.center = [center.lat, center.lng];
                this.zoom = this.$refs.map.mapObject.getZoom();
            }
        },
        //  onMapClick(event) {
        //     if (this.isCreatingPolygon) {
        //         const latlng = event.latlng;
        //         this.polygonPoints.push([latlng.lat, latlng.lng]);
        //         if (this.polygonPoints.length > 1) {
        //             const lastIndex = this.polygonPoints.length - 1;
        //             const lastPoint = this.polygonPoints[lastIndex];
        //             const previousPoint = this.polygonPoints[lastIndex - 1];
        //             const polyline = L.polyline(
        //                 [previousPoint, lastPoint],
        //                 this.polylineOptions
        //             );
        //             polyline.addTo(this.$refs.map.mapObject);
        //             this.savePolygon();
        //         }
        //     }
        // },
        onMapClick(event) {
            if (this.whichForm == "baum") {
                if (this.isCreatingPolygon) {
                    const latlng = event.latlng;
                    this.markers.push([latlng.lat, latlng.lng]);
                    if (this.markers.length > 1) {
                        this.markers.shift();
                    }
                    this.saveMarker([latlng.lat, latlng.lng]);
                    this.markerAdded = true;
                }
            } else {
                if (this.isCreatingPolygon) {
                    this.markers = []
                    const latlng = event.latlng;
                    this.polygonPoints.push([latlng.lat, latlng.lng]);
                    if (this.polygonPoints.length > 1) {
                        const lastIndex = this.polygonPoints.length - 1;
                        const lastPoint = this.polygonPoints[lastIndex];
                        const previousPoint = this.polygonPoints[lastIndex - 1];
                        const polyline = L.polyline(
                            [previousPoint, lastPoint],
                            this.polylineOptions
                        );
                        polyline.addTo(this.$refs.map.mapObject);
                        this.savePolygon();
                    }
                }
            }
        },
        saveMarker(marker) {
            // Save the marker or send its coordinates to the database
            // You can modify this method to suit your needs
            this.$emit("latlngs-updated", marker);
        },
        resetMap() {
            const map = this.$refs.map.mapObject;
            map.eachLayer((layer) => {
                if (layer instanceof L.Polyline) {
                    const latlngs = layer.getLatLngs().flat();
                    const coords = this.polygonPoints.map((point) => L.latLng(point));
                    if (JSON.stringify(latlngs) === JSON.stringify(coords)) {
                        map.removeLayer(layer);
                    }
                }
            });

            // Reset the polygon points array
            this.polygonPoints = [];
            this.$emit("latlngs-updatedArea", this.polygonPoints);
            this.savePolygon();
        },
        undoPolygon() {
            if (this.polygonPoints.length === 0) {
                return;
            }

            const map = this.$refs.map.mapObject;

            // Remove the last polyline from the map
            let lastPolyline;
            map.eachLayer((layer) => {
                if (layer instanceof L.Polyline) {
                    lastPolyline = layer;
                }
            });
            if (lastPolyline) {
                map.removeLayer(lastPolyline);
            }

            // Store the last removed polygon in a variable for redo functionality
            const lastPolygonPoints = this.polygonPoints.pop();

            // Update the polygonPoints array and emit the updated value
            this.$emit("latlngs-updatedArea", this.polygonPoints);

            // Optionally, you can also call this.savePolygon() here to update your data model.

            // Implement redo functionality by storing the last removed polygon in a variable
            // and adding it back to the map.
            this.redoPolygonPoints.push(lastPolygonPoints);
            this.savePolygon();
        },
        redoPolygon() {
            if (this.redoPolygonPoints && this.redoPolygonPoints.length > 0) {
                const map = this.$refs.map.mapObject;

                // Get the last removed polygon from the redoPolygonPoints array
                const lastPolygonPoints = this.redoPolygonPoints.pop();

                if (lastPolygonPoints) {
                    // Add the polygon back to the polygonPoints array and emit the updated value
                    this.polygonPoints.push(lastPolygonPoints);
                    this.$emit("latlngs-updatedArea", this.polygonPoints);

                    // Create a polyline for the last polygon and add it to the map
                    const polyline = L.polyline(lastPolygonPoints, this.polylineOptions);
                    polyline.addTo(map);
                    this.savePolygon();
                }
            }
        },

        togglePolygonCreationMode() {
            this.isCreatingPolygon = !this.isCreatingPolygon;
            if (this.isCreatingPolygon) {
                this.polygonPoints = [];
            }
        },
        closeIsCreatingPolygonMode() {
            this.isCreatingPolygon = false;
        },
        savePolygon() {
            // Send the polygonPoints array to the database
            // and store the new polygon in this.mapRegions
            this.mapRegions.push({
                coordinates: this.polygonPoints,
            });

            this.$emit("latlngs-updatedArea", this.polygonPoints);
        },
        handeldata(data) {
            this.mapKey += 1;
            setTimeout(() => {
                this.center = data;
            }, 100);
        },
        ...mapActions(["mapRegion"]),
        setMapDataRegions() {
            axios
                .get("https://tilia-ch.rrota.org/api/Inventory/MapRegion")
                .then((response) => {
                    this.mapRegions = response.data;

                })
                .catch((error) => {
                    // Handle Errors here.
                    // var errorCode = error.code;
                    // var errorMessage = error.message;
                    console.log(error);

                    // ...
                });
        },
        async setMapDataAreaRegions() {
            const filteredItemsArea = this.getAreaItem.filter(el => el !== undefined ? el : null)
            if (filteredItemsArea.length) {
                await axios
                    .get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`)
                    .then((response) => {

                        const items = response.data.areaRegions.filter(element => filteredItemsArea.some(item => item.areaId === element.areaId));

                        this.AreaRegions = items;

                    })
                    .catch((error) => {
                        // Handle Errors here.
                        // var errorCode = error.code;
                        // var errorMessage = error.message;
                        console.log(error);

                        // ...
                    });
            }
        },

        async setMapDataTreeRegions() {
            this.loading = true;  // Added to indicate loading state
            let items = [];
            let iterations = 9;  // Number of iterations to perform
            let allIterationsDone = false;

            const filteredItemsTree = this.getTreeItem.filter(el => el !== undefined ? el : null);

            for (let index = 0; index < iterations; index++) {
                try {
                    const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}?skip=${this.skip}&take=${this.take}`);
                    if (response.status == 200) {
                        const treeRegions = response.data.treeRegions;
                        if (treeRegions.length == 0) {
                            allIterationsDone = true;
                            break; // Stop the loop if the response is an empty array
                        }

                        let data = treeRegions.map(region => ({
                            ...region,
                            coordinates: [region.coordinates], // Convert single coordinate pair to an array of arrays
                        }));

                        let filteredData;
                        if (this.prevRoute.name == "Pflegeplan" || this.prevRoute.name == "Baumkontrolle") {
                            filteredData = data.filter(element =>
                                filteredItemsTree.some(item => item.treeId === element.treeId)
                            );
                        } else {
                            filteredData = data.filter(element =>
                                filteredItemsTree.some(item => item.id === element.treeId)
                            );
                        }

                        items.push(...filteredData);
                        this.skip += this.take;
                        this.treeRegions = [...this.treeRegions, ...filteredData];
                    }
                } catch (error) {
                    console.log(error);
                    // Continue to the next iteration if an error occurs
                }

                if (allIterationsDone) {
                    break; // Stop the loop if all iterations are done
                }
            }

            // Finalize after all iterations
            if (this.prevRoute.name == "Pflegeplan" || this.prevRoute.name == "Baumkontrolle") {
                this.centerMapOnZoneFromInFromTasksORBaumControll(items);
            } else {
                this.centerMapOnZoneFromIn(items);
            }

            this.loading = false;  // Indicate that loading is complete
        }
        ,

        centerMapOnZoneFromIn(trees) {
            console.log("corodasdjaujda",trees);
            this.markers = trees.map(coord => coord.coordinates[0])
            let selectedTrees = null
            // Find the average latitude and longitude of all points in the zone
            if(trees.length == 0 && this.inventarCoordinates == null){
                selectedTrees = [[47.211785,7.541221],[47.211811,7.541753],[47.211541,7.541522]]
            } else if (trees.length == 0 && this.inventarCoordinates) {
                selectedTrees = JSON.parse(this.inventarCoordinates)

            } else {
             selectedTrees = trees.map(obj => [obj.coordinates[0][0],obj.coordinates[0][1]] )

            }
            console.log("selectedTrees",selectedTrees);

            const latlngs = selectedTrees;
            const latlngBounds = L.latLngBounds(latlngs);
            const center = latlngBounds.getCenter();
            // Use Leaflet's fitBounds method to set the map view to display the entire zone
            this.$refs.map.mapObject.fitBounds(latlngBounds);

            // Update the component's center and zoom properties to reflect the new map view
            this.center = [center.lat, center.lng];
            this.zoom = this.$refs.map.mapObject.getZoom();
        },
        centerMapOnZoneFromInFromTasksORBaumControll(treeTasks) {
            this.markers = treeTasks.map(coord => coord.coordinates[0])
            let selectedTrees = null
            // Find the average latitude and longitude of all points in the zone
            if(treeTasks.length == 0 && this.inventarCoordinates == null){
                selectedTrees = [[47.211785,7.541221],[47.211811,7.541753],[47.211541,7.541522]]
            } else if (treeTasks.length == 0 && this.inventarCoordinates) {
                selectedTrees = JSON.parse(this.inventarCoordinates)

            } else {
             selectedTrees = treeTasks.map(obj => [obj.coordinates[0][0],obj.coordinates[0][1]] )

            }
            // Find the average latitude and longitude of all points in the zone
            const latlngs = selectedTrees;
            const latlngBounds = L.latLngBounds(latlngs);
            const center = latlngBounds.getCenter();
            // Use Leaflet's fitBounds method to set the map view to display the entire zone
            this.$refs.map.mapObject.fitBounds(latlngBounds);

            // Update the component's center and zoom properties to reflect the new map view
            this.center = [center.lat, center.lng];
            this.zoom = this.$refs.map.mapObject.getZoom();
            // this.markers = selectedTreeCoordinates
        },
        onZoomEnd() {
            this.zoom = this.$refs.map.mapObject.getZoom(); // Update the zoom property
            this.updateTooltipFontSize(); // Update the font size of the tooltip


        },
        updateTooltipFontSize() {
            const customPolygons = document.querySelectorAll(".custom-polygon");
            customPolygons.forEach((polygon) => {
                const tooltip = polygon.querySelector(".leaflet-tooltip");
                if (tooltip) {
                    const fontSize = Math.round(polygon.style.weight * 0); // Calculate the font size based on the polygon's weight
                    tooltip.style.fontSize = `${fontSize}px`; // Set the font size of the tooltip
                }
            });
        },
        handleCustomForm(form) {
            this.formType = form
        }
    },
    created() {

        this.setMapDataRegions();

    },

    watch: {
        checkForm(newVal) {
            return newVal
        }
    },
    // beforeUnmount() {
    //     // Remove the zoomend event listener before unmounting the component
    //     this.$refs.map.mapObject.off("zoomend", this.onZoomEnd);
    // },
    mounted() {
        this.setMapDataTreeRegions()
console.log('cooridnate sne store', this.inventarCoordinates);
        this.setMapDataAreaRegions()
        // this.setMapDataTreeRegions()
        // this.$parent.$on('form-event', this.handleCustomForm); 
        this.$refs.map.mapObject.on("zoomend", this.onZoomEnd);
        this.$parent.$on('send-cordinates', (dataFromParent) => {

            const latlngBounds = L.latLngBounds(dataFromParent);
            const center = latlngBounds.getCenter();

            // Use Leaflet's fitBounds method to set the map view to display the entire zone
            this.$refs.map.mapObject.fitBounds(latlngBounds);

            // Update the component's center and zoom properties to reflect the new map view
            this.center = [center.lat, center.lng];
            this.zoom = this.$refs.map.mapObject.getZoom();

        });
        this.$parent.$on("my-event", this.handeldata);

    },
};
</script>


<style lang="scss">
  .change-layer{
          position: absolute;
          top: -10px;
          right: 43px;
          width: 130px;
          height: 53px;
          background-color: #2a2b2a;
          opacity: 0.8;
          color: #fff;

          .layer-select{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            text-align: center;
            color: #fff;

            .item-select{
              width: 100%;
              color: #fff;
              font-size: 15px;
              background-color: #2a2b2a;
            }
          }
      }

.date-link {
    text-decoration: underline;
    cursor: pointer;
}

.leaflet-tooltip {
    background: unset;
    border: unset;
}

.left-side {
    height: calc(100vh - 130px);
    width: 43px;

    img {
        margin-top: 116px;
        margin-bottom: 32px;
        cursor: pointer;
    }

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        width: 200px;
        height: 24px;
        margin-left: -92px;
        color: #ffffff;
        transform: rotate(-90deg);
        cursor: pointer;
    }
}

.map {
    .citites-tooltip{
        color: #7f1111;
        font-size: 20px;
        font-weight: 700;
    }
    .change-layer {
        position: absolute;
        top: -10px;
        right: 43px;
        width: 150px;
        height: 60px;
        background-color: #2a2b2a;
        opacity: 0.8;
        color: #fff;
    }

    .white-tooltip {
        color: white !important;
    }

    .custom-polygon {
        color: white !important;
        font-size: 12px !important;
    }
    .custom-polygon-cadastral {
        color: #2E6B45 !important; 
        font-size: 12px !important; 
        font-weight: 600 !important;
        }

    .leaflet-popup-tip-container {
        .leaflet-popup-tip {
            background: #1c1e1c !important;
        }
    }

    .leaflet-container a.leaflet-popup-close-button {
        top: 20px !important;
        right: 20px !important;
        font: 26px/24px Tahoma, Verdana, sans-serif !important;
        color: #fff !important;
    }

    .leaflet-popup-content-wrapper {
        background: #1c1e1c;
        border: 1px solid #494949;
        border-radius: 10px;
        width: 470px;
        height: auto;

        .leaflet-popup-content {
            max-width: 90%;
            width: 90% !important;
            background: #1c1e1c;


            .popup-content {
                padding: 10px;
                background: #1c1e1c;
                color: #fff;
                border-radius: 5px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                padding-top: 35px;
                padding-bottom: 35px;

                .title {
                    display: flex;
                    border-bottom: 1px solid #494949;
                    padding-bottom: 10px;

                    h2 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: #ffffff;
                        margin-left: 20px;
                    }
                }

                .selected-yars {
                    margin-top: 30px;

                    select {
                        width: 309px;
                        height: 40px;
                        background: #1c1e1c;
                        border: 1px solid #494949;
                        border-radius: 5px;
                        color: #fff;
                    }
                }

                .informations {
                    margin-top: 10px;
                    border-bottom: 1px solid #494949;
                }

                .footer-links {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;

                    .btn {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        /* identical to box height */
                        display: flex;
                        align-items: center;
                        text-decoration-line: underline;

                        /* Wireframes/Colors/White */

                        color: #ffffff;

                        &:first-child {
                            &::before {
                                content: "";
                                display: inline-block;
                                background-image: url("../../../../assets/images/print.png");
                                background-position: center;
                                background-size: cover;
                                width: 20px;
                                height: 21px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .popup-title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .popup-description {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 25px;
                /* identical to box height */

                /* UI/White */

                color: #ffffff;
                display: flex;
                justify-content: space-between;

                span:last-child {
                    margin-right: 70px;
                    text-align: left;
                }
            }

            .popup-image {
                width: 100%;
                max-height: 200px;
                object-fit: cover;
                margin-top: 10px;
            }

        }
    }

    .dark-bg {
        background-color: #2A2B2A;
        opacity: 0.8;
    }

    width: 100%;
    height: calc(100vh - 130px);

    .custom-icon {
        background-image: none !important;
    }

    .tooltip-content {
        font-size: 12px !important;
        color: #fff;
    }

    .leaflet-control-attribution {
        display: none !important;
    }

    // .leaflet-tooltip {
    //     background-color: transparent;
    //     border: 0;
    //     box-shadow: none;
    //     font-style: normal;
    //     color: #2A2B2A;
    // }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        background-color: #fff;
    }

    .leaflet-left .leaflet-control-zoom {
        display: none;
    }

    .leaflet-right .leaflet-control-zoom {
        position: relative;
        top: 271px;
        right: -10px;
        background: #2a2b2a;
        opacity: 0.8;
    }

    .leaflet-right .leaflet-control-zoom .leaflet-bar {
        border-radius: none;
    }

    .leaflet-touch .leaflet-bar a {
        background: #2a2b2a;
        opacity: 0.8;
        color: white;
    }

    .my-controls {
        position: relative;
        top: 261px;
        right: -10px;
        /* padding: 1.8px; */
        background: #2a2b2a;
        opacity: 0.8;
        /* border-top-left-radius: 2px; */
        /* border-top-right-radius: 2px; */
        background: #2a2b2a;
        opacity: 0.8;
        /* width: 30px; */
        padding-bottom: 5px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        button.location {
            color: white;
            width: 34px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            /* text-align: center; */
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6px;
            border-top: 1px solid #a4a4a4;
            padding-top: 7px;
            cursor: pointer;
        }

        button.add-points {
            border-top: 1px solid #a4a4a4;
            padding-top: 10px;
            cursor: pointer;
        }
    }

    .topright {
        position: absolute;
        top: -10px;
        right: -10px;

        .undo-redo-clean {
            background: #2a2b2a;
            opacity: 0.8;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .close-btn-img {
                position: absolute;
                left: 10px;
            }

            .btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px;
                border-right: 1px solid #a4a4a4;
                padding-right: 10px;
                cursor: pointer;
                width: 32px;
                height: 32px;
            }

            .btn:last-child {
                border-right: none;
            }

            span {
                margin-top: 5px;
            }
        }
    }
}
</style>