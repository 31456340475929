<template>
  <v-container fluid d-flex class="klkl">
    <div class="left-side-map">
      <img
        class="vertical-title-img"
        src="../../../../assets/images/arrow-right-white.png"
        alt="arrow-right-white"
        @click="closeViewMap"
      />
      <h2 class="vertical-h2" @click="closeViewMap">Karte schließen</h2>
    </div>
    <div class="map print-map">
      <l-map
        :maxZoom="maxZoom"
        :minZoom="minZoom"
        :zoom="zoom"
        :key="mapKey"
        :center="center"
        @click="onMapClick"
        ref="map"
        @ready="onMapReady"
        id="map-id"
      >
        <l-tile-layer :options="{ maxZoom: maxZoom }"
          :url="tileLayerUrl"
          :attribution="currentLayer.attribution"
        ></l-tile-layer>
        <l-control class="layer-control-inventory-map">
          <div class="change-layer">
              <select class="layer-select" v-model="selectedOption" @change="updateTileLayerUrl">
                  <option class="item-select" v-for="(option, index) in options" :key="index" :value="option">
                      {{ option }}
                  </option>
              </select>
          </div>
          </l-control>

        <l-control class="topright">
          <div class="undo-redo-clean">
            <button class="btn" @click="closeViewMap">
              <img class="close-btn-img" src="../../../../assets/images/close_white_border.png" alt="location" width="32px" height="32px" />
              <span></span>
            </button>
          </div>
        </l-control>
        <l-control-zoom />
        <l-control class="my-controls">
          <button class="location" @click="goToMyLocation">
            <img
              src="../../../../assets/images/my-location.png"
              alt="location"
            />
          </button>
        </l-control>
        <l-layer-group>
          <l-polygon v-for="(swiss,index) in switzerlandCities" :key="`city-${index}`" :lat-lngs="[swiss.coordinates]"> 
             <l-tooltip class="citites-tooltip" :options="{permanent: true, direction: 'center'}">{{ swiss.city }}</l-tooltip>
          </l-polygon>
          <l-polygon
            v-for="(zone, index) in mapRegions"
            :ref="zone"
            :key="index"
            :lat-lngs="zone.coordinates"
            :options="polygonOptions"
            :disabled="zone.isLocked"
            :fillColor="zone.isLocked ? '#2A2B2A' : polygonOptions.fillColor"
            @click="zone.isLocked == true ? null : onZone(zone, $event)"
            :interactive="!zone.isLocked"
          >
            <l-popup
              ref="popup"
              :close-button="false"
              :options="popupOptions"
              v-if="showPopup"
            >
              <div class="popup-content">
                <div class="title">
                  <img
                    src="../../../../assets/images/map-icon-viewed.png"
                    alt="map-icon"
                    width="27px"
                    height="25px"
                  />
                  <h2>{{inventarInformation.name}}</h2>
                </div>
                <div class="selected-yars">
                  <v-select
                    v-model="selectedYear"
                    :items="orderedYears"
                    outlined
                    @change="changeyear"
                  >
                    <template #selectedYear="{ selectedYear }">
                      {{ selectedYear || "Select a year" }}
                    </template>
                  </v-select>
                </div>
                <div class="informations">
                  <div class="popup-description">
                    <span>Anz. Baume: (Stk.)</span>
                    <span v-if="inventarInformation.totalTrees">{{
                      inventarInformation.totalTrees
                    }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="popup-description">
                    <span>Norm [h]</span>
                    <span v-if="inventarInformation.normalHours">
                      {{ inventarInformation.normalHours }}
                    </span>
                    <span v-else> - </span>
                  </div>
                  <div class="popup-description">
                    <span>Spez [h]</span>
                    <span v-if="inventarInformation.specialHours">{{
                      inventarInformation.specialHours
                    }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="popup-description">
                    <span>Aufwand Material Fr.</span>
                    <span v-if="inventarInformation.material">
                      {{ inventarInformation.material % 1 === 0 ? inventarInformation.material + '.00' : inventarInformation.material }}
                    </span>
                    <span v-else> - </span>
                  </div>
                </div>
                <div class="footer-links">
                  <button class="btn" @click.prevent="printMap">
                    Karte Drucken
                  </button>
                </div>
              </div>
            </l-popup>

            <l-tooltip
              v-if="zone.isLocked"
              :options="{ permanent: true, direction: 'center' }"
              ><div class="locked">
                <span>{{ zone.name }}</span>
                <img src="../../../../assets/images/lock.svg" alt="locked" />
              </div>
            </l-tooltip>
            <l-tooltip
              :class="{ 'clicked-zone': zoneClicked === zone }"
              v-else
              :options="{ permanent: true, direction: 'center' }"
              >{{ zone.name }}</l-tooltip
            >
          </l-polygon>
          <l-polygon v-for="(zone, index) in AreaRegions" :key="`area${index}`" :lat-lngs="zone.coordinates" :color="zone.colorTypeId" :fill-color="zone.colorTypeId" :options="polygonOptionsArea" @click="onArea(zone, $event)">
                <!-- <l-tooltip ref="tooltip">{{ zone.inventoryId }}</l-tooltip> -->
                <!-- <l-popup
            ref="popup"
            :content="JSON.stringify(zone.name)"
            :options="popupOptions"
          ></l-popup> -->
          <l-popup ref="popup" :close-button="false" :options="popupOptions">
                        <div class="popup-content">
                            <div class="title">
                                <img src="../../../../assets/images/map-icon-viewed.png" alt="map-icon" width="27px" height="25px" />
                                <h2 v-if="inventarTitle ">{{ inventarTitle }}</h2>
                                <h2 v-else>-</h2>
                            </div>

                            <div class="informations" v-if="areaInfo">
                                <div class="popup-description">
                                        <span>ID:</span>
                                        <span> {{ treeInfo.name }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Latein:</span>
                                        <span> {{ treeInfo.treeTypeLatinName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Deutsch:</span>
                                        <span> {{ treeInfo.treeTypeGermanName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Baumhöhe(m):</span>
                                        <span> {{ lastTechnicalDataInfoHeight }}</span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Kronen Ø(m):</span>
                                        <span> {{ lastTechnicalDataInfoCrown}}</span>
                                    </div>
                                    <div v-for="task in treeInfo.mapData" :key="task.taskId" class="popup-description">
                                      <span>{{ task.worktype }}:</span>
                                      <span class="date-link"  v-if="!task.isTreeExamination" @click.prevent="goToTaskProfil(task.taskId, task.taskDate)">{{ task.taskDate }}</span>
                                      <span class="date-link" v-else-if="task.isTreeExamination" @click.prevent="goToBaumControll(task.taskId, task.treeExaminationId)">{{ task.taskDate }}</span>
                                      <span v-else> - </span>
                                  </div>
                            </div>
                            <div v-else>Loading...</div>
                            <div class="footer-links">
                              <button class="btn" @click.prevent="printMap">
                                Karte Drucken
                              </button>
                            </div>
                        </div>
                    </l-popup>
                <l-tooltip :options="{permanent: true, direction: 'center'}">area - {{ zone.areaId }}</l-tooltip>
            </l-polygon>
            <l-polygon v-if="zoom >= 16" v-for="(zone, index) in treeRegions" :key="`tree${index}`" :lat-lngs="zone.coordinates" :color="zone.colorTypeId" :fill-color="zone.colorTypeId" :options="getPolygonOptions(zone.crownDiameter, zone.id)" @click="onTree(zone, $event)">
              <l-popup ref="popup" :close-button="false" :options="popupOptions" >
                        <div class="popup-content">
                            <div class="title">
                                <img src="../../../../assets/images/map-icon-viewed.png" alt="map-icon" width="27px" height="25px" />
                                <h2 v-if="inventarTitle ">{{ inventarTitle }}</h2>
                                <h2 v-else>-</h2>
                            </div>

                            <div class="informations" v-if="treeInfo">
                                <div class="popup-description">
                                        <span>Baum ID:</span>
                                        <span> {{ treeInfo.name }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Latein:</span>
                                        <span> {{ treeInfo.treeTypeLatinName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Deutsch:</span>
                                        <span> {{ treeInfo.treeTypeGermanName }} </span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Baumhöhe(m):</span>
                                        <span> {{ lastTechnicalDataInfoHeight }}</span>
                                    </div>
                                    <div class="popup-description">
                                        <span>Kronen Ø(m):</span>
                                        <span> {{ lastTechnicalDataInfoCrown}}</span>
                                    </div>
                                    <div v-for="task in treeInfo.mapData" :key="task.taskId" class="popup-description">
                                      <span>{{ task.worktype }}:</span>
                                      <span class="date-link"  v-if="!task.isTreeExamination" @click.prevent="goToTaskProfil(task.taskId, task.taskDate)">{{ task.taskDate }}</span>
                                      <span class="date-link" v-else-if="task.isTreeExamination" @click.prevent="goToBaumControll(task.taskId, task.treeExaminationId)">{{ task.taskDate }}</span>
                                      <span v-else> - </span>
                                  </div>
                            </div>
                            <div v-else>loading...</div>

                            <div class="footer-links">
                              <button class="btn" @click.prevent="printMap">
                                Karte Drucken
                              </button>
                            </div>
                        </div>
                    </l-popup>      
                    <l-tooltip 
                            :options="{ permanent: true, direction: 'center' }" 
                            :class="selectedOption === 'Cadastral' ? 'custom-polygon-cadastral' : 'custom-polygon'">
                            {{ zone.name }}
                            </l-tooltip>
            </l-polygon>
          <l-polyline
            v-if="polygonPoints.length > 1"
            :lat-lngs="polygonPoints"
            :color="color"
            :options="polylineOptions"
          >
          </l-polyline>
        </l-layer-group>
      </l-map>
    </div>
    <CustomLoader v-show="isLoading" />

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import domToImage from "dom-to-image";
import leafletImage from "leaflet-image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as L from 'leaflet'
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import CustomLoader from "../../../../components/custom_components/CustomLoader.vue";
import moment from 'moment';

import {
  LMap,
  LTileLayer,
  LPolygon,
  LTooltip,
  LLayerGroup,
  LIcon,
  LPopup,
  LDrawToolbar,
  LControlZoom,
  LControl,
  LMarker
} from "vue2-leaflet";
import axios from "axios";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw";

export default {
  name: "Inventarliste",
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LTooltip,
    LLayerGroup,
    LIcon,
    LPopup,
    LDrawToolbar,
    LControlZoom,
    LControl,
    LMarker,
    CustomLoader
  },
  props: ["centerr"],
  data() {
    return {
      maxZoom: 20,
      minZoom:13,
      zoom:0,
      mapKey: 0,
      center: [72.012782, 0.208447], // set your initial center
      url: `https://tile.openstreetmap.org/{z}/{x}/{y}.png`, // set your tile layer URL
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      apikey: "AIzaSyC27AbpxpWs0UKZEzUcq-qQ4zDcI_D4jKo",
      tileLayerUrl:'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
      currentLayer: {
        name: "",
        baseUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg', // Replace with your WMS service URL
        cadastarUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.kantone.cadastralwebmap-farbe/default/current/3857/{z}/{x}/{y}.png', // Replace with your WMS service URL
        apiKey: "kOlaYPuPlEZLGataPQ1r",
        attribution:'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
      },
      selectedOption: 'Standard',
      options: [
          'Standard',
          'Cadastral'
      ],
      clickLatLngs: [],
      isCreatingPolygon: false,
      polygonPoints: [],
      redoPolygonPoints: [],
      mapRegions: [],
      AreaRegions: [],
      treeRegions: [],
      treeRegionsToShow:[],
      polygonOptions: {
        fillColor: "transparent",
        color: "#1DB954",
        weight: 3,
        opacity: 0.6,
        fillOpacity: 0.6
      },
      polylineOptions: {},
      polygonOptionsArea: {
            weight: 1,
            border: "3px solid #A4A4A4",
            opacity: 0.6,
            fillOpacity: 0.6,
        },
        polygonOptionsTree: {
            weight: 200,
            border: "3px solid #A4A4A4",
            opacity: 0.6,
            fillOpacity: 0.6
        },
      popupOptions: {
        closeButton: true,
        autoClose: false,
        autoOpenPopup: true
      },
      inventarInformation: {},
      layers: [
        {
          name: "Regions",
          active: true,
          leafletLayer: null,
          layer: L.layerGroup()
        }
      ],
      years: [],
      selectedYear: 2023,
      zoneId: null,
      loader: false,
      isLoading:false,
      zoneClicked: null,
      clickedLayer: null,
      mapReady: false,
      snapshotOptions:{
        hideElementsWithSelectors: [
          ".leaflet-control-container",
          ".leaflet-dont-include-pane",
          "#snapshot-button"
        ],
        hidden: true
      },
      screenshotter:null,
      showPopup:true,
      isLoading:false,
      skip: 0,
      take: 300,
      clickedZoneId: null,
      treeInfo: null,
      areaInfo:null,
      allIterationsDone:false
    };
  },
  computed: {
    ...mapGetters(["mapRegionData", "error", "loggedInUser","inventarCoordinates","inventarTitle","inventarId","switzerlandCities"]),
    lastTechnicalDataInfoHeight(){
                // Assuming treeInfo.technicalDatas is an array
                if (this.treeInfo.technicalDatas.length > 0) {
                const lastIndex = this.treeInfo.technicalDatas.length - 1;
                const lastObject = this.treeInfo.technicalDatas[lastIndex].treeHeight;
                return lastObject
                } else {
                   return this.treeInfo.technicalDatas[0].treeHeight
                }
            },
            lastTechnicalDataInfoCrown(){
                // Assuming treeInfo.technicalDatas is an array
                if (this.treeInfo.technicalDatas.length > 0) {
                const lastIndex = this.treeInfo.technicalDatas.length - 1;
                const lastObject = this.treeInfo.technicalDatas[lastIndex].crownDiameter;
                    return lastObject
                } else {
                    return this.treeInfo.technicalDatas[0].crownDiameter
                }
            },
    orderedYears() {
      return this.years.slice().reverse();
    }
  },
  methods: {
    updateTileLayerUrl() {
        if (this.selectedOption === 'Cadastral') {
            this.skip = 0
            this.take = 300
            this.AreaRegions = []
            this.treeRegions = []
            this.tileLayerUrl = this.currentLayer.cadastarUrl;
            this.centerMapOnZone();
        } else {
            this.skip = 0
            this.take = 300
            this.AreaRegions = []
            this.treeRegions = []
            this.tileLayerUrl = this.currentLayer.baseUrl;
            this.centerMapOnZone();
        }

        // Update other map-related data
        this.setMapDataAreaRegions()
        this.setMapDataTreeRegions();

    },
    onArea(zone, event) {
            this.clickedZoneId = zone.id;
            this.openAreaPopup = true;
            axios
                .get(`https://tilia-ch.rrota.org/api/Tree/GetArea/${this.inventarId}/${zone.areaId}`)
                .then(response => {
                    this.areaInfo = response.data

                })
                .catch(error => {
                    console.log("error", error);
                })
        },
        onTree(zone, event) {
            this.clickedZoneId = zone.treeId;
            this.openTreePopup = true
                axios
                .get(`https://tilia-ch.rrota.org/api/Tree/GetTree/${zone.inventoryId}/${zone.treeId}`)
                .then(response => {
                  response.data.mapData.forEach(item => {
                  item.taskDate = moment(item.taskDate).format("DD-MM-YYYY");
                    });

                    // Sort the mapData array by taskDate in ascending order (oldest to newest)
                    response.data.mapData.sort((a, b) => {
                    return moment(a.taskDate, "DD-MM-YYYY").diff(moment(b.taskDate, "DD-MM-YYYY"));
                     });
                     this.treeInfo = response.data;
                        })
                .catch(error => {
                    console.log("error", error);
                })
           

        },
    getPolygonOptions(size, id) {
      let scaledSize = 25;

      switch (true) {
          case size > 18:
              scaledSize = size * 2;
              break;
          case size >= 12 && size <= 18:
              scaledSize = size * 3;
              break;
          case size >= 8 && size < 12:
              scaledSize = size * 4;
              break;
          case size >= 5 && size < 8:
              scaledSize = size * 6;
              break;
          case size >= 2 && size < 5:
              scaledSize = size * 10;
              break;
          default:
              scaledSize = size * 20;
          }
            const strokeWidth = id === this.clickedZoneId ? 3 : scaledSize;
            return {
                fillColor: '#fff',
                color: this.color,
                weight: scaledSize, // Bind weight property to a dynamic value (e.g., size)
                border: `${3}px solid ${this.color}`, // Bind border property to a dynamic value
                opacity: 0.6,
                fillOpacity: 0.6,
                // className: "custom-polygon",
            };
        },
        async printMap() {
      this.showPopup = false
        this.isLoading = true
    setTimeout(() => {
      try {

      // Get the Leaflet map instance from the Vue component
      const map = this.$refs.map.mapObject;

      // Set up snapshotter
      const snapshotOptions = {
        hideElementsWithSelectors: [
          ".leaflet-control-container",
          "#snapshot-button",
        ],
        hidden: true,
      };

      this.screenshotter = new SimpleMapScreenshoter(snapshotOptions);
      this.screenshotter.addTo(map);

      } catch (error) {
      console.error(error.message);
      }
    },3000)

    setTimeout(()=>{
      this.takeScreenShot();

    },4000)
},


takeScreenShot() {
  // Ensure that the screenshotter is initialized
  if (this.screenshotter) {
    // Set up screenshot function
    this.screenshotter
      .takeScreen("image")
      .then(async (image) => {
        // ... (rest of your code)
        console.log("::::image",image);
        const formattedDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        const link = document.createElement("a");
        link.download = `screenshot.png`;
      link.href = image;
      link.click();
        this.isLoading = false
      })
      .catch((e) => {
        alert(e.toString());
      });
  } else {
    console.error("SimpleMapScreenshoter is not initialized");
  }
},

    goToInventarInfo(zone) {
      this.$router.push({
        name: "InventarInfo",
        query: { id: zone.inventoryId },
        params: { title: zone.name }
      });
    },
    changeyear() {
      this.fetchInventarInfo();
    },
    fetchInventarInfo() {
      axios
        .get(
          `https://tilia-ch.rrota.org/api/Inventory/MapInfo/${this.zoneId}/${this.selectedYear}`
        )
        .then(response => {
          console.log(this.inventarInformation);
          this.inventarInformation = response.data;
        })
        .catch(error => {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          console.log(error);

          // ...
        });
    },
    async fetchYears() {
      try {
        const response = await axios.get(
          "https://tilia-ch.rrota.org/api/Inventory/GetYears"
        );
        console.log("years", response.data);
        this.years = response.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },
    onZone(zone, event) {
      const id = this.$route.params.id;
      const year = this.$route.params.year;
      this.zoneId = zone.inventoryId;
      const map = this.$refs.map.mapObject;
      this.zoneClicked = zone;
      // Find the clicked layer
      const clickedLayer = event.target;

      // // Do something with the clicked layer
      // const clickedZoneStyle = document.querySelector(".leaflet-tooltip");
      // clickedZoneStyle.style.color = "white"

      // clickedLayer.setStyle({
      //   fillColor: '#151914',
      //   color: '#1DB954',
      //   weight: 2,
      //   border: "3px solid #A4A4A4",
      //   opacity: 0.7,
      // });

      this.clickedLayer = clickedLayer;

      // Listen to popup events
      clickedLayer.on("popupopen", this.onPopupOpen);
      clickedLayer.on("popupclose", this.onPopupClose);

      axios
        .get(
          `https://tilia-ch.rrota.org/api/Inventory/MapInfo/${zone.inventoryId}/${year}`
        )
        .then(response => {
          this.inventarInformation = response.data;
        })
        .catch(error => {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          console.log(error);

          // ...
        });
    },

    onPopupOpen(e) {
      // Update the style of the clicked layer
      this.clickedLayer.setStyle({
        fillColor: "#151914",
        color: "#1DB954",
        weight: 2,
        border: "3px solid #A4A4A4",
        opacity: 0.7
      });
      // Get the tooltip element for the clicked layer
      const tooltip = this.clickedLayer.getTooltip().getElement();
      // Update the tooltip style
      tooltip.classList.add("white-tooltip");
    },
    onPopupClose(e) {
      // Reset the style of the clicked layer
      this.clickedLayer.setStyle({
        fillColor: "transparent",
        color: "#1DB954",
        weight: 3,
        opacity: 0.6,
        border: "3px solid #A4A4A4",
        fillOpacity: 0.6
      });
      // Get the tooltip element for the clicked layer
      const tooltip = this.clickedLayer.getTooltip().getElement();
      // Update the tooltip style
      tooltip.classList.remove("white-tooltip");
    },
    goToMyLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const latlng = [position.coords.latitude, position.coords.longitude];
          this.$refs.map.mapObject.setView(latlng, 18); // Set the map view to your location with zoom level 15
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },

    // changeTileLayer(layer) {
    //   this.selectedLayer = layer;
    //   this.currentLayer = layer;
    // },
    //    onMapClick(e) {
    //     const latlng = e.latlng;
    //     this.clickLatLngs.push([latlng.lat, latlng.lng]);
    //      if (this.clickLatLngs.length >= 3) {
    //       const lastIndex = this.mapRegions.length - 1;
    //       const lastRegion = this.mapRegions[lastIndex].region;
    //       lastRegion.push(this.clickLatLngs);
    //       this.clickLatLngs = [];
    //       const obj = {
    //         inventoryId: 152,
    //         name: "inventory i ri",
    //         lastRegion
    //       }
    //       this.mapRegions.push(obj)
    //       this.$emit('items-updated', this.clickLatLngs);
    //     }

    // },
    onMapReady() {
      this.mapReady = true;
      this.centerMapOnZone();
    },
    closeViewMap() {
      this.$router.go(-1);
    },
    goToBaumControll(taskId, treeExaminationId){
            this.$router.push({
                name:"BaumKontrollDetails",   
                params: {
                    taskId: taskId,
                    treeId: this.treeInfo.id,
                    treeName: this.treeInfo.treeName,
                    treeExaminationId: treeExaminationId,
                    isEditBK: treeExaminationId != 0,
                    isSealed: false,
                    treeType: this.treeInfo.treeType,
                    viewOnly: false
                }
                })
            },
    goToTaskProfil(taskId, taskDate){
      const year = taskDate.split('-')[2];
            this.$router.push({
                name:"Pflegeplan Eintrag",
                params:{year, taskId}})
    },
    onMapClick(event) {
      if (this.isCreatingPolygon) {
        const latlng = event.latlng;
        this.polygonPoints.push([latlng.lat, latlng.lng]);
        if (this.polygonPoints.length > 1) {
          const lastIndex = this.polygonPoints.length - 1;
          const lastPoint = this.polygonPoints[lastIndex];
          const previousPoint = this.polygonPoints[lastIndex - 1];
          const polyline = L.polyline(
            [previousPoint, lastPoint],
            this.polylineOptions
          );
          polyline.addTo(this.$refs.map.mapObject);
          this.savePolygon();
        }
      }
    },
    handeldata(data) {
      this.mapKey += 1;
      setTimeout(() => {
        this.center = data;
      }, 100);
    },
    ...mapActions(["mapRegion"]),
    setMapDataRegions() {
      axios
        .get("https://tilia-ch.rrota.org/api/Inventory/MapRegionByUser")
        .then(response => {
          this.mapRegions = response.data;
        })
        .catch(error => {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          console.log(error);

          // ...
        });
    },
    async setMapDataAreaRegions() {
            await axios
                .get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`)
                .then((response) => {

                    this.AreaRegions = response.data.areaRegions;

                })
                .catch((error) => {
                    // Handle Errors here.
                    // var errorCode = error.code;
                    // var errorMessage = error.message;
                    console.log(error);

                    // ...
                });
        },
        async setMapDataTreeRegions() {
    this.loading = true;
    const maxIterations = 9; // Number of iterations to perform
    let allIterationsDone = false;

    try {
        for (let index = 0; index < maxIterations; index++) {
            const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}?skip=${this.skip}&take=${this.take}`);
            if (response.status === 200) {
                if (response.data.treeRegions.length === 0) {
                    console.log("u ndal");
                    allIterationsDone = true;
                    break; // Stop the loop if the response is an empty array
                }
                console.log("heci te posht");
                
                const data = response.data.treeRegions.map(region => ({
                    ...region,
                    coordinates: [region.coordinates], // Convert single coordinate pair to an array of arrays
                }));

                this.skip += this.take;
                this.treeRegions = [...this.treeRegions, ...data];
            }
        }
    } catch (error) {
        console.log(error);
    }

    this.allIterationsDone = allIterationsDone;
    this.centerMapOnZone(); // Call the appropriate method
    this.loading = false;
}
,

    centerMapOnZone() {
      // Find the average latitude and longitude of all points in the zone
      const latlngs = JSON.parse(this.$route.params.coord ? this.$route.params.coord : "[[47.211785,7.541221],[47.211811,7.541753],[47.211541,7.541522]]");
      console.log("latlngsssss", latlngs);
      const latlngBounds = L.latLngBounds(latlngs);
      const center = latlngBounds.getCenter();

      // Use Leaflet's fitBounds method to set the map view to display the entire zone
      this.$refs.map.mapObject.fitBounds(latlngBounds);

      // Update the component's center and zoom properties to reflect the new map view
      setTimeout(()=>{
        this.center = [center.lat, center.lng];
        this.zoom = this.$route.params.coord ? this.$refs.map.mapObject.getZoom() : 14;
      },200)
    },
    onZoomEnd() {
      this.zoom = this.$refs.map.mapObject.getZoom(); // Update the zoom property
      this.updateTooltipFontSize(); // Update the font size of the tooltip
    },
    updateTooltipFontSize() {
        const customPolygons = document.querySelectorAll(".custom-polygon");
        customPolygons.forEach((polygon) => {
            const tooltip = polygon.querySelector(".leaflet-tooltip");
            if (tooltip) {
                const fontSize = Math.round(polygon.style.weight * 0.1); // Calculate the font size based on the polygon's weight
                tooltip.style.fontSize = `${fontSize}px`; // Set the font size of the tooltip
            }
        });
    },
  },
  created() {
    this.setMapDataRegions();

    this.fetchYears();
  },

  beforeUnmount() {
        // Remove the zoomend event listener before unmounting the component
        this.$refs.map.mapObject.off("zoomend", this.onZoomEnd);
    },
  mounted() {
    this.$refs.map.mapObject.on("zoomend", this.onZoomEnd);

    this.setMapDataAreaRegions();
    this.setMapDataTreeRegions()


    const id = this.$route.params.id;
  }
};
</script>
<style lang="scss">
 .custom-polygon-cadastral {
        color: #2E6B45 !important; 
        font-size: 12px !important; 
        font-weight: 600 !important;
        }

.select-map{
    top: 183px !important;
}


.date-link{
  text-decoration: underline;
  cursor: pointer;
}
.left-side-map {
  height: calc(100vh - 130px);
  width: 43px;
  .vertical-title-img {
    margin-top: 116px;
    margin-bottom: 32px;
    cursor: pointer;
  }

  .vertical-h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 200px;
    height: 24px;
    margin-left: -92px;
    color: #ffffff;
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.map {
  width: 100%;
  height: calc(100vh - 130px);
  .citites-tooltip{
        color: #7f1111;
    }
  .change-layer{
          position: absolute;
          top: -10px;
          right: 43px;
          width: 130px;
          height: 53px;
          background-color: #2a2b2a;
          opacity: 0.8;
          color: #fff;

          .layer-select{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            text-align: center;
            color: #fff;

            .item-select{
              width: 100%;
              color: #fff;
              font-size: 15px;
              background-color: #2a2b2a;
            }
          }
      }

  .white-tooltip {
    color: white !important;
  }
  .custom-polygon{
        color: white !important;
        font-size: 11px !important;
    }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background: #1c1e1c !important;
    }
  }

  .leaflet-container a.leaflet-popup-close-button {
    top: 20px !important;
    right: 20px !important;
    font: 26px/24px Tahoma, Verdana, sans-serif !important;
    color: #fff !important;
  }
  .leaflet-popup-content-wrapper {
    background: #1c1e1c;
    border: 1px solid #494949;
    border-radius: 10px;
    width: 470px;
    height: auto;

    .leaflet-popup-content {
      max-width: 90%;
      width: 90% !important;
      background: #1c1e1c;

      div {
        .popup-content {
          padding: 10px;
          background: #1c1e1c;
          color: #fff;
          border-radius: 5px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          padding-top: 35px;
          padding-bottom: 35px;

          .title {
            display: flex;
            border-bottom: 1px solid #494949;
            padding-bottom: 10px;
            h2 {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              margin-left: 20px;
            }
          }

          .selected-yars {
            margin-top: 30px;

            select {
              width: 309px;
              height: 40px;
              background: #1c1e1c;
              border: 1px solid #494949;
              border-radius: 5px;
              color: #fff;
            }
          }

          .informations {
            border-bottom: 1px solid #494949;
          }

          .footer-links {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .btn {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height */
              display: flex;
              align-items: center;
              text-decoration-line: underline;

              /* Wireframes/Colors/White */

              color: #ffffff;

              &:first-child {
                &::before {
                  content: "";
                  display: inline-block;
                  background-image: url("../../../../assets/images/print.png");
                  background-position: center;
                  background-size: cover;
                  width: 20px;
                  height: 21px;
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .popup-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .popup-description {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 25px;
          /* identical to box height */

          /* UI/White */

          color: #ffffff;
          display: flex;
          justify-content: space-between;

          span:last-child {
            margin-right: 70px;
            text-align: left;
          }
        }

        .popup-image {
          width: 100%;
          max-height: 200px;
          object-fit: cover;
          margin-top: 10px;
        }
      }
    }
  }

  .leaflet-tooltip {
    background-color: transparent;
    border: 0;
    font-size: 12px;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #2a2b2a;

    .locked {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.85;
      z-index: 1;
      span {
        z-index: 0;
      }
      img {
        z-index: 9998;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .clicked-zone .leaflet-tooltip {
    color: #fff !important;
  }
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    background-color: #fff;
  }
  .leaflet-left .leaflet-control-zoom {
    display: none;
  }
  .leaflet-right{
    display: flex;
    align-items: center;
  }
  .leaflet-right .leaflet-control-zoom {
    position: fixed;
    top: 42%;
    right: 14px;
    background: #2a2b2a;
    opacity: 0.8;
  }
  .leaflet-right .leaflet-control-zoom .leaflet-bar {
    border-radius: none;
  }
  .leaflet-touch .leaflet-bar a {
    background: #2a2b2a;
    opacity: 0.8;
    color: white;
  }
  .my-controls {
    position: fixed;
    top: 48.9%;
    right: 14px;
    /* padding: 1.8px; */
    background: #2a2b2a;
    opacity: 0.8;
    /* border-top-left-radius: 2px; */
    /* border-top-right-radius: 2px; */
    background: #2a2b2a;
    opacity: 0.8;
    /* width: 30px; */
    padding-bottom: 5px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button.location {
      color: white;
      width: 34px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      /* text-align: center; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      border-top: 1px solid #a4a4a4;
      padding-top: 7px;
      cursor: pointer;
    }

    button.add-points {
      border-top: 1px solid #a4a4a4;
      padding-top: 10px;
      cursor: pointer;
    }
  }
  .topright .undo-redo-clean {
    background: #2a2b2a;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;
    .btn {
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.8;
      width:34px;
      height:34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      border-right: 1px solid #a4a4a4;
      padding-right: 10px;
      cursor: pointer;
      max-width: unset;
    }
    .btn:last-child {
      border-right: none;
    }
    span {
      margin-top: 5px;
    }
  }
}
</style>
