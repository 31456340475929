var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"custom-buttons-holder"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"mt-8",attrs:{"items":_vm.orderedYearsWithAll,"outlined":"","menu-props":"auto","hide-details":""},on:{"change":_vm.yearChange},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v("Farbe")]),_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.colorOptions,"item-value":"value","hide-details":"","outlined":""},on:{"change":_vm.colorChange},model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"first-row"},[(!_vm.hideFilters)?_c('v-col',{attrs:{"cols":"2"}},[_c('a',{staticClass:"carte",on:{"click":_vm.viewSelectedItemOnMap}},[_c('img',{staticClass:"karte mr-2",attrs:{"src":require("../../../assets/images/map.png"),"alt":""}}),_c('span',[_vm._v("Karte")]),(_vm.showInfo)?_c('div',{staticClass:"information"},[_vm._v(" Sie müssen mindestens einen Baum oder ein Gebiet auswählen. ")]):_vm._e()])]):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"new-btn",attrs:{"block":"","size":"x-large","height":"54px","color":"#1db954"},on:{"click":_vm.createNewTask}},[_c('v-icon',[_vm._v("mdi mdi-plus-thick")]),_vm._v(" Neue Pflegemassnahme ")],1)],1)],1)],1)],1),(!_vm.hideFilters)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn-toggle',{staticClass:"three-buttons",attrs:{"active-class":"custom-active","mandatory":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"custom-outlined",style:({
                  'background-color': hover ? '#1DB954' : 'transparent',
                  color: hover ? 'black' : 'white',
                  'border-color': hover ? 'transparent' : '#494949'
                }),attrs:{"size":"x-large","height":"54px","outlined":""},on:{"click":function($event){return _vm.onTaskFilter(_vm.TaskTypes.Open)}}},[_vm._v(" Offen ")])]}}],null,false,1096589650)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"custom-outlined",style:({
                  'background-color': hover ? '#1DB954' : 'transparent',
                  color: hover ? 'black' : 'white',
                  'border-color': hover ? 'transparent' : '#494949'
                }),attrs:{"size":"x-large","height":"54px","outlined":""},on:{"click":function($event){return _vm.onTaskFilter(_vm.TaskTypes.Closed)}}},[_vm._v(" Erledigt ")])]}}],null,false,389748112)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"custom-outlined",style:({
                  'background-color': hover ? '#1DB954' : 'transparent',
                  color: hover ? 'black' : 'white',
                  'border-color': hover ? 'transparent' : '#494949'
                }),attrs:{"size":"x-large","height":"54px","outlined":""},on:{"click":function($event){return _vm.onTaskFilter(_vm.TaskTypes.All)}}},[_vm._v(" Gesamtplan ")])]}}],null,false,3458506969)})],1)],1)],1):_vm._e()],1)],1),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('BaumControlListDatatable',{attrs:{"hideSelection":_vm.hideSelection,"headers":_vm.isMobileViewport ? _vm.headersMobile : _vm.headers,"tableData":_vm.tableDataWithYear,"showSelect":true,"singleSelect":false,"actions":[
        { id: 1, icons: require('../../../assets/images/icons/eye-outline.svg'), action: 'view', params: '' },
        {
          id: 2,
          icons: require('../../../assets/images/icons/edit-outline.svg'),
          action: 'createEditBaumK',
          params: ''
        },
        { id: 3, icons: require('../../../assets/images/icons/pencil-outline.svg'), action: 'edit', params: '' }
      ],"userCanView":_vm.userCanView,"userCanEdit":_vm.userCanEdit,"userCanDelete":_vm.userCanDelete},on:{"createEditBaumK":_vm.createEditBKMethod,"edit":_vm.editTaskMethod,"view":_vm.viewBKMethod,"selectedRow":_vm.getSelectedItems}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }